import React from 'react';
import './ProfileTabs.css';
import Tab from './Tab/Tab';
import constants from '../../../constants/constants';

function ProfileTabs(props) {
    const { activeTab, changeTabHandler } = props;

    return (
        <div className='profile-tabs-container row'>
            <div className={`${activeTab === 'matchesToApprove' ? 'active-tab-container' : ''} tab-column col-3`}>
                <Tab
                    activeTab={activeTab}
                    changeTabHandler={changeTabHandler}
                    tabLabel={constants.TABS.MATCHES_TO_APPROVE}
                    tabId={'matchesToApprove'}
                />
            </div>
            <div className={`${activeTab === 'matchesAreWaiting' ? 'active-tab-container' : ''} tab-column col-3`}>
                <Tab
                    activeTab={activeTab}
                    changeTabHandler={changeTabHandler}
                    tabLabel={constants.TABS.MATCHES_ARE_WAITING}
                    tabId={'matchesAreWaiting'}
                />
            </div>
            <div className={`${activeTab === 'rejectedMatches' ? 'active-tab-container' : ''} tab-column col-3`}>
                <Tab activeTab={activeTab} changeTabHandler={changeTabHandler} tabLabel={constants.TABS.REJECTED_MATCHES} tabId={'rejectedMatches'} />
            </div>
            <div className={`${activeTab === 'rejectedMatchesByMe' ? 'active-tab-container' : ''} tab-column col-3`}>
                <Tab
                    activeTab={activeTab}
                    changeTabHandler={changeTabHandler}
                    tabLabel={constants.TABS.REJECTED_MATCHES_BY_ME}
                    tabId={'rejectedMatchesByMe'}
                />
            </div>
        </div>
    );
}

export default ProfileTabs;
