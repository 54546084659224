const env = process.env.REACT_APP_ENV;

const PROD_SERVER = 'https://tennis-matches-server.vercel.app';
const DEV_SERVER = 'http://localhost:4000';

const dev = {
    apiBaseHost: DEV_SERVER,
};

const prod = {
    apiBaseHost: PROD_SERVER,
};

const envConfigs = {
    dev,
    prod,
};

const ClientConfig = {
    ...envConfigs[env],
};

export default ClientConfig;
