import { SET_COURTS, COURT_SENDING_REQUEST, COURT_SENDING_REQUEST_FAILURE } from '../constants/courtConstants';
import { LOGOUT } from '../constants/authConstants';

const initialState = {
    courts: [],
    isLoading: false,
    error: null,
};

const courtsReducer = (state = initialState, action) => {
    switch (action.type) {
        case COURT_SENDING_REQUEST:
            return { ...state, isLoading: true, error: null };
        case COURT_SENDING_REQUEST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case SET_COURTS:
            return {
                ...state,
                courts: action.courts,
                isLoading: false,
                error: null,
            };
        case LOGOUT:
            return {
                ...state,
                courts: [],
                isLoading: false,
                error: null,
            };
        default:
            return state;
    }
};

export default courtsReducer;
