import {
    AUTH_SENDING_REQUEST,
    AUTH_SENDING_REQUEST_FAILURE,
    SET_ACCESS_TOKEN,
    LOGIN_SUCCESSFULLY,
    REGISTER_REQUEST_SENDING,
    REGISTER_REQUEST_SUCCESSFULLY,
    REGISTER_REQUEST_FAILURE,
    AUTH_RESET_ERRORS,
    LOGOUT,
    LOGIN_AS_GUEST,
    SEND_EMAIL_PASSWORD_REQUEST_FAILURE,
    SEND_EMAIL_PASSWORD_REQUEST_SENDING,
    SEND_EMAIL_PASSWORD_REQUEST_SUCCESS,
    SEND_RESET_PASSWORD_REQUEST_SENDING,
    SEND_RESET_PASSWORD_REQUEST_SUCCESS,
    SEND_RESET_PASSWORD_REQUEST_FAILURE,
} from '../constants/authConstants';
import constants from '../../constants/constants';
import AuthServiceImpl from '../../service/AuthServiceImpl';
import ToastUtil from '../../utils/ToastUtils';

export const getAccessToken = (userName) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: AUTH_SENDING_REQUEST,
            });
            const token = await AuthServiceImpl.getAccessToken(userName);

            dispatch({
                type: SET_ACCESS_TOKEN,
                token: token?.data?.access_token,
            });
        } catch (error) {
            dispatch({
                type: AUTH_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
        }
    };
};

export const login = (payload) => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        try {
            dispatch({
                type: AUTH_SENDING_REQUEST,
            });
            const player = await AuthServiceImpl.login(token, payload.userName, payload.password);
            if (player?.data?.phoneNumber) {
                dispatch({
                    type: LOGIN_SUCCESSFULLY,
                    phoneNumber: player?.data.phoneNumber,
                });
                return player?.data?.phoneNumber;
            } else if (player?.data?.code) {
                dispatch({
                    type: AUTH_SENDING_REQUEST_FAILURE,
                    error: player?.data,
                });
                ToastUtil.error('שגיאה בטלפון או בסיסמא');
            }
        } catch (error) {
            dispatch({
                type: AUTH_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה בטלפון או בסיסמא');
        }
    };
};

export const loginAsGuest = () => {
    return {
        type: LOGIN_AS_GUEST,
    };
};

export const register = (player) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: REGISTER_REQUEST_SENDING,
            });
            const registerRes = await AuthServiceImpl.register(player);
            if (registerRes?.data?.status && registerRes?.data?.status === 'complete') {
                dispatch({
                    type: REGISTER_REQUEST_SUCCESSFULLY,
                });
                ToastUtil.success('הרשמתך בוצעה בהצלחה!');
            } else if (registerRes?.data?.code) {
                dispatch({
                    type: REGISTER_REQUEST_FAILURE,
                    error: registerRes?.data,
                });
                ToastUtil.error('שגיאה - אנא נסה שנית');
            }
        } catch (error) {
            dispatch({
                type: REGISTER_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה - אנא נסה שנית');
        }
    };
};

export const sendEmailforgotPassword = (payload) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SEND_EMAIL_PASSWORD_REQUEST_SENDING,
            });
            const sendPasswordRes = await AuthServiceImpl.sendEmailforgotPassword(payload);
            if (sendPasswordRes?.data?.status && sendPasswordRes?.data?.status === 'complete') {
                dispatch({
                    type: SEND_EMAIL_PASSWORD_REQUEST_SUCCESS,
                });
                ToastUtil.success('מייל נשלח בהצלחה');
            } else if (sendPasswordRes?.data?.code) {
                dispatch({
                    type: SEND_EMAIL_PASSWORD_REQUEST_FAILURE,
                    error: sendPasswordRes?.data,
                });
                ToastUtil.error('שגיאה - אנא נסה שנית');
            }
        } catch (error) {
            const message = error?.response ? error?.response?.data?.message : 'שגיאה - אנא נסה שנית';
            dispatch({
                type: SEND_EMAIL_PASSWORD_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error(message);
        }
    };
};

export const resetPassword = (payload) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: SEND_RESET_PASSWORD_REQUEST_SENDING,
            });
            const resetPasswordRes = await AuthServiceImpl.resetPassword(payload);
            if (resetPasswordRes?.data?.status && resetPasswordRes?.data?.status === 'complete') {
                dispatch({
                    type: SEND_RESET_PASSWORD_REQUEST_SUCCESS,
                });
                ToastUtil.success('סיסמא אופסה בהצלחה');
                return true;
            } else if (resetPasswordRes?.data?.code) {
                dispatch({
                    type: SEND_RESET_PASSWORD_REQUEST_FAILURE,
                    error: resetPasswordRes?.data,
                });
                ToastUtil.error('שגיאה - אנא נסה שנית');
                return false;
            }
        } catch (error) {
            const message = error?.response ? error?.response?.data?.message : 'שגיאה - אנא נסה שנית';
            dispatch({
                type: SEND_RESET_PASSWORD_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error(message);
            return false;
        }
    };
};

export const resetErrorMessages = () => {
    return {
        type: AUTH_RESET_ERRORS,
    };
};

export const logout = () => {
    return {
        type: LOGOUT,
    };
};
