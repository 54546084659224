import {
    SET_PLAYER_GAMES,
    SET_PLAYER,
    SET_PLAYERS_LIST,
    PLAYER_SENDING_REQUEST,
    PALYER_SENDING_REQUEST_FAILURE,
    SET_SELECTED_PLAYER,
    RELEASE_SELECTED_PLAYER,
} from '../constants/playerConstants';
import constants from '../../constants/constants';
import PlayerServiceImpl from '../../service/PlayerServiceImpl';
import ToastUtil from '../../utils/ToastUtils';

export const setPlayer = () => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        const phoneNumber = sessionStorage.getItem(constants.SESSION_STORAGE.PLAYER_PHONE_NUMBER);
        if (phoneNumber) {
            try {
                dispatch({
                    type: PLAYER_SENDING_REQUEST,
                });
                const player = await PlayerServiceImpl.getPlayerByPhoneNumber(token, phoneNumber);
                if (player?.data?.code) {
                    dispatch({
                        type: PALYER_SENDING_REQUEST_FAILURE,
                        error: player?.data,
                    });
                    ToastUtil.error('שגיאה זמנית');
                } else {
                    dispatch({
                        type: SET_PLAYER,
                        player: player?.data,
                    });
                }
            } catch (error) {
                dispatch({
                    type: PALYER_SENDING_REQUEST_FAILURE,
                    error: error?.response ? error?.response?.data : error,
                });
                ToastUtil.error('שגיאה זמנית');
            }
        }
    };
};

export const getAllPlayersList = () => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        try {
            dispatch({
                type: PLAYER_SENDING_REQUEST,
            });
            const playersList = await PlayerServiceImpl.getAllPlayersList(token);
            if (playersList?.data?.code) {
                dispatch({
                    type: PALYER_SENDING_REQUEST_FAILURE,
                    error: playersList?.data,
                });
                ToastUtil.error('שגיאה זמנית');
            } else {
                dispatch({
                    type: SET_PLAYERS_LIST,
                    playersList: playersList?.data,
                });
            }
        } catch (error) {
            dispatch({
                type: PALYER_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};

export const getPlayerGames = () => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        try {
            dispatch({
                type: PLAYER_SENDING_REQUEST,
            });
            const playerGames = await PlayerServiceImpl.getPlayerGames(token);
            if (playerGames?.data?.code) {
                dispatch({
                    type: PALYER_SENDING_REQUEST_FAILURE,
                    error: playerGames?.data,
                });
                ToastUtil.error('שגיאה זמנית');
            } else {
                dispatch({
                    type: SET_PLAYER_GAMES,
                    playerGames: playerGames?.data,
                });
            }
        } catch (error) {
            dispatch({
                type: PALYER_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};

export const setSelectedPlayer = (playerId) => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        try {
            dispatch({
                type: PLAYER_SENDING_REQUEST,
            });
            const selectedPlayer = await PlayerServiceImpl.getPlayerById(token, playerId);
            dispatch({
                type: SET_SELECTED_PLAYER,
                selectedPlayer: selectedPlayer?.data,
            });
        } catch (error) {
            dispatch({
                type: PALYER_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};

export const releaseSelectedPlayer = () => {
    return {
        type: RELEASE_SELECTED_PLAYER,
    };
};
