import './RegistrationForm.css';
import Button from '@mui/material/Button';
import { useState } from 'react';
import constants from '../../../constants/constants';
import Select from '@mui/material/Select';
import isInputNumeric from '../../../generic actions/IsInputNumeric';
import MenuItem from '@mui/material/MenuItem';
import * as citiesActions from '../../../redux/actions/citiesActions';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect } from 'react';
import emailValidation from '../../../generic actions/EmailValidation';
import phoneValidation from '../../../generic actions/PhoneValidation';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import RegistrationInput from './RegistrationInput/RegistrationInput';
import Loader from '../loader/Loader';

function RegistrationForm(props) {
    const { submitRegistrationForm } = props;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [city, setCity] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const { isLoading: isLoadingCities, cities } = useSelector((state) => state.cities);
    const { isRegister, isLoading: isLoadingRegistration } = useSelector((state) => state.auth.registration);

    const isLoading = isLoadingRegistration || isLoadingCities;

    const dispatch = useDispatch();

    const inputsChangeHandler = (setState) => (event) => {
        const input = event.target.value;

        if (event.target.id === 'usernameInput') {
            if (isInputNumeric(input) || input === '') {
                setState(input);
            }
        } else {
            setState(input);
        }
    };

    const submitFormHandler = () => {
        const payload = {
            firstName,
            lastName,
            phoneNumber: userName,
            password,
            cityCode: city,
            dateOfBirth,
            email,
        };
        submitRegistrationForm(payload);
    };

    useEffect(() => {
        if (
            firstName.length > 1 &&
            lastName.length > 1 &&
            phoneValidation(userName) &&
            emailValidation(email) &&
            password.length > 0 &&
            rePassword.length > 0 &&
            rePassword === password &&
            dateOfBirth &&
            city
        ) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [firstName, lastName, userName, email, password, rePassword, dateOfBirth, city]);

    useEffect(() => {
        dispatch(citiesActions.getAllCities());
    }, []);

    return (
        <form>
            <div className={`registration-container ${isLoading ? 'blur-background' : ''}`}>
                {isRegister ? (
                    <>
                        <div className='confirmation-row-container'>
                            <span className='confirmation-text'>{constants.REGISTRATION.REGISTER_SUCCESSFULLY}</span>
                        </div>
                    </>
                ) : (
                    <>
                        {' '}
                        <div className='inputs-container row'>
                            <div className='input-container-column col-6'>
                                <RegistrationInput
                                    input={firstName}
                                    inputsChangeHandler={inputsChangeHandler}
                                    setInput={setFirstName}
                                    maxLength={15}
                                    id={'firstNameInput'}
                                    label={constants.REGISTRATION.FIRST_NAME}
                                    type={''}
                                    validation={firstName.length > 1}
                                />
                            </div>
                            <div className='input-container-column col-6'>
                                <RegistrationInput
                                    input={lastName}
                                    inputsChangeHandler={inputsChangeHandler}
                                    setInput={setLastName}
                                    maxLength={15}
                                    id={'lastNameInput'}
                                    label={constants.REGISTRATION.LAST_NAME}
                                    type={''}
                                    validation={lastName.length > 1}
                                />
                            </div>
                        </div>
                        <div className='inputs-container row'>
                            <div className='input-container-column col-6'>
                                <RegistrationInput
                                    input={userName}
                                    inputsChangeHandler={inputsChangeHandler}
                                    setInput={setUserName}
                                    maxLength={10}
                                    id={'usernameInput'}
                                    label={constants.REGISTRATION.USER_NAME}
                                    type={'username'}
                                    validation={phoneValidation(userName)}
                                />
                            </div>
                            <div className='input-container-column col-6'>
                                <RegistrationInput
                                    input={email}
                                    inputsChangeHandler={inputsChangeHandler}
                                    setInput={setEmail}
                                    maxLength={50}
                                    id={'emailInput'}
                                    label={constants.REGISTRATION.EMAIL}
                                    type={''}
                                    validation={emailValidation(email)}
                                />
                            </div>
                        </div>
                        <div className='inputs-container row'>
                            <div className='input-container-column col-6'>
                                <RegistrationInput
                                    input={password}
                                    inputsChangeHandler={inputsChangeHandler}
                                    setInput={setPassword}
                                    maxLength={15}
                                    id={'passwordInput'}
                                    label={constants.REGISTRATION.PASSWORD}
                                    type={'password'}
                                    validation={password.length > 0}
                                />
                            </div>
                            <div className='input-container-column col-6'>
                                <RegistrationInput
                                    input={rePassword}
                                    inputsChangeHandler={inputsChangeHandler}
                                    setInput={setRePassword}
                                    maxLength={15}
                                    id={'rePasswordInput'}
                                    label={constants.REGISTRATION.RE_PASSWORD}
                                    type={'password'}
                                    validation={rePassword === password && rePassword.length > 0}
                                />
                            </div>
                        </div>
                        <div className='inputs-container row'>
                            <div className='input-container-column col-6'>
                                <div className='input-container'>
                                    <div className='input-label-container row'>
                                        <div className='col-1'>
                                            {dateOfBirth && (
                                                <span>
                                                    <CheckSharpIcon className='registration-check-icon' />
                                                </span>
                                            )}
                                        </div>
                                        <div className='col-11'>
                                            <span className='input-label-span'>{constants.REGISTRATION.DATA_OF_BIRTH}</span>
                                            <span className='mandatory-filed-input'>*</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                format='dd/MM/yyyy'
                                                onChange={(date) => {
                                                    const dateObj = new Date(date);
                                                    dateObj.setHours(dateObj.getHours() + 3);
                                                    const adjustedISOString = dateObj.toISOString();
                                                    setDateOfBirth(adjustedISOString);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                            <div className='input-container-column col-6'>
                                <div className='input-container'>
                                    <div className='input-label-container row'>
                                        <div className='col-1'>
                                            {city && (
                                                <span>
                                                    <CheckSharpIcon className='registration-check-icon' />
                                                </span>
                                            )}
                                        </div>
                                        <div className='col-11'>
                                            <span className='input-label-span'>{constants.REGISTRATION.CITY}</span>
                                            <span className='mandatory-filed-input'>*</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <Select
                                            className='select-input-custom'
                                            value={city}
                                            size='small'
                                            onChange={inputsChangeHandler(setCity)}
                                            displayEmpty
                                        >
                                            <MenuItem value='' disabled={true}>
                                                <em>{constants.REGISTRATION.PICK}</em>
                                            </MenuItem>
                                            {cities.map((city) => {
                                                return (
                                                    <MenuItem key={city.code} value={city.code}>
                                                        {city.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='button-cuntainer row'>
                            <Button disabled={isButtonDisabled} onClick={submitFormHandler} type='button' size='small' variant='outlined'>
                                {constants.REGISTRATION.REGISTER}
                            </Button>
                        </div>
                    </>
                )}
            </div>
            {isLoading && (
                <div className='loader-container'>
                    <Loader />
                </div>
            )}
        </form>
    );
}

export default RegistrationForm;
