import { GET_CITIES_SENDING_REQUEST, GET_CITIES_REQUEST_FAILURE, GET_CITIES_REQUEST_SUCCESS } from '../constants/cityConstants';

const initialState = {
    cities: [],
    isLoading: false,
    error: null,
};

const citiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CITIES_SENDING_REQUEST:
            return { ...state, isLoading: true, error: null };
        case GET_CITIES_REQUEST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case GET_CITIES_REQUEST_SUCCESS:
            return {
                ...state,
                cities: action.cities,
                isLoading: false,
                error: null,
            };

        default:
            return state;
    }
};

export default citiesReducer;
