import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './Table.css';
import PlayersTable from '../../../components/mobile/PlayersTable/PlayersTable';
import * as playerActions from '../../../redux/actions/playerAction';
import { useDispatch } from 'react-redux';
import ServerError from '../../../components/mobile/ServerError/ServerError';
import PlayerCard from '../../../components/mobile/PlayerCard/PlayerCard';

function Table() {
    const { playerGames, isLoading, selectedPlayer, error } = useSelector((state) => state.player);
    const dispatch = useDispatch();

    const fetchData = async () => {
        dispatch(playerActions.getPlayerGames());
    };

    const handleRowClick = async (player) => {
        dispatch(playerActions.setSelectedPlayer(player.playerId));
    };

    const backButtonHandler = () => {
        dispatch(playerActions.releaseSelectedPlayer());
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='table-page-container'>
            {error && <ServerError reload={fetchData} />}
            {!error && !isLoading && selectedPlayer && (
                <PlayerCard player={selectedPlayer} backButtonHandler={backButtonHandler} displayBackButton={true} displayEditButton={false} />
            )}
            {!error && !isLoading && !selectedPlayer && <PlayersTable handleRowClick={handleRowClick} playerGames={playerGames} />}
        </div>
    );
}

export default Table;
