import React from 'react';
import './SearchMatchesByPlayer.css';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';

function SearchMatchesByPlayer(props) {
    const { onSelectPlayerHandler, onSelectOpponentHandler } = props;

    const { playersList } = useSelector((state) => state.player);
    const { selectedPlayerForResults, selectedOpponentForResults } = useSelector((state) => state.match);

    const playersOptions = playersList?.map((player) => {
        return {
            label: `${player.firstName} ${player.lastName}`,
            playerId: player.id,
        };
    });

    return (
        <>
            <div className='search-player-container row'>
                <div className='search-player-column col-5'>
                    <span className='search-player-span-title'>בחר שחקן</span>
                </div>
                <div className='search-player-column col-7'>
                    <Autocomplete
                        className='tennis-matches'
                        sx={{
                            '& .MuiAutocomplete-clearIndicator': {
                                visibility: 'visible',
                            },
                        }}
                        disablePortal
                        value={selectedPlayerForResults}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={playersOptions}
                        onChange={(e, player) => onSelectPlayerHandler(e, player)}
                        renderInput={(params) => <TextField {...params} label='שחקן' InputLabelProps={{ shrink: false }} />}
                    />
                </div>
            </div>
            {selectedPlayerForResults && (
                <div className='search-player-container row'>
                    <div className='search-player-column col-5'>
                        <span className='search-player-span-title'>בחר שחקן יריב</span>
                    </div>
                    <div className='search-player-column col-7'>
                        <Autocomplete
                            className='tennis-matches'
                            disablePortal
                            sx={{
                                '& .MuiAutocomplete-clearIndicator': {
                                    visibility: 'visible',
                                },
                            }}
                            value={selectedOpponentForResults}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            options={playersOptions?.filter((player) => player.playerId !== selectedPlayerForResults.playerId)}
                            onChange={(e, player) => onSelectOpponentHandler(e, player)}
                            renderInput={(params) => <TextField {...params} label='יריב' InputLabelProps={{ shrink: false }} />}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default SearchMatchesByPlayer;
