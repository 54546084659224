import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import playerReducer from '../reducer/playerReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import matchReducer from '../reducer/matchReducer';
import courtsReducer from '../reducer/courtReducer';
import thunk from 'redux-thunk';
import authReducer from '../reducer/authReducer';
import citiesReducer from '../reducer/citiesReducer';

const rootReducer = combineReducers({
    court: courtsReducer,
    cities: citiesReducer,
    player: playerReducer,
    match: matchReducer,
    auth: authReducer,
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export const persistor = persistStore(store);
