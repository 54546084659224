import * as React from 'react';
import './SelectPlayerSection.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ButtonSteps from '../ButtonSteps/ButtonSteps';
import constants from '../../../../constants/constants';
import { useState } from 'react';
import { useEffect } from 'react';

function SelectPlayerSection(props) {
    const [playerSelectedId, setPlayerSelected] = useState('');
    const players = props.playersList;

    const handleChange = (value) => {
        const playerId = value.target.value;
        setPlayerSelected(playerId);

        const player = players.find((player) => {
            return player.id === value.target.value;
        });

        props.playerSelectedIdChanged(player ? player : { id: '' });
    };

    useEffect(() => {
        setPlayerSelected(props.playerId);
    }, []);

    return (
        <>
            <Select value={playerSelectedId} size='small' onChange={(value) => handleChange(value)} displayEmpty>
                <MenuItem value=''>
                    <em>{constants.ADD_GAME.PLAYER}</em>
                </MenuItem>
                {players.map((player, index) => {
                    if (player.id !== props.playerInContext.playerId) {
                        const playerName = `${player.firstName} ${player.lastName}`;
                        return (
                            <MenuItem key={index} value={player.id}>
                                {playerName}
                            </MenuItem>
                        );
                    }
                })}
            </Select>
            <ButtonSteps
                isBackDisabled={true}
                isFinalStep={false}
                isContinueDisabled={playerSelectedId === '' ? true : false}
                handleNext={props.handleNext}
                handleBack={props.handleBack}
            />
        </>
    );
}

export default SelectPlayerSection;
