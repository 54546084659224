import {
    SET_PLAYER,
    SET_PLAYER_GAMES,
    SET_PLAYERS_LIST,
    PLAYER_SENDING_REQUEST,
    PALYER_SENDING_REQUEST_FAILURE,
    SET_SELECTED_PLAYER,
    RELEASE_SELECTED_PLAYER,
} from '../constants/playerConstants';
import { LOGOUT } from '../constants/authConstants';

const initialState = {
    player: {},
    playerGames: [],
    playersList: [],
    selectedPlayer: null,
    isLoading: false,
    error: null,
};

const playerReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT:
            return {
                isLoggedIn: false,
                player: {},
                playerGames: [],
                playersList: [],
                selectedPlayer: null,
                isLoading: false,
                error: null,
            };
        case SET_PLAYER:
            return {
                ...state,
                player: action.player,
                isLoading: false,
                error: null,
            };
        case SET_PLAYER_GAMES:
            return {
                ...state,
                playerGames: action.playerGames,
                selectedPlayer: null,
                isLoading: false,
                error: null,
            };
        case SET_PLAYERS_LIST:
            return {
                ...state,
                playersList: action.playersList,
                isLoading: false,
                error: null,
            };
        case SET_SELECTED_PLAYER:
            return { ...state, selectedPlayer: action.selectedPlayer, isLoading: false, error: null };
        case RELEASE_SELECTED_PLAYER:
            return { ...state, selectedPlayer: null };
        case PLAYER_SENDING_REQUEST:
            return { ...state, isLoading: true, error: null };
        case PALYER_SENDING_REQUEST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
};

export default playerReducer;
