import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './ForgotPasswordForm.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Recaptcha from '../recaptcha/Recaptcha';
import constants from '../../../constants/constants';

const ForgotPasswordForm = (props) => {
    const { submitForgotPasswordForm } = props;
    const captchaRef = useRef(null);

    const validationSchema = yup.object().shape({
        email: yup.string().email(constants.LOGIN_SCREEN.INVALID_EMAIL).required(constants.LOGIN_SCREEN.REQUIRE_FILED),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let captchaV2Token = '';
            if (captchaRef.current?.getValue()) {
                captchaV2Token = captchaRef.current.getValue();
            }
            captchaRef.current?.reset();
            formik.resetForm();

            await submitForgotPasswordForm({
                email: values.email,
                captchaV2Token,
            });
        },
    });

    return (
        <div className='forgot-password-container'>
            <form onSubmit={formik.handleSubmit}>
                <div>
                    <span className='title-text-forgot-password'>{constants.LOGIN_SCREEN.RESET_TITLE}</span>
                </div>
                <div>
                    <span className='title-text-forgot-password'>{constants.LOGIN_SCREEN.EMAIL_EXPLAINATION}</span>
                </div>
                <div className='text-field-container-forgot'>
                    <TextField
                        size='small'
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange('email')}
                        placeholder={constants.LOGIN_SCREEN.EMAIL}
                        variant='outlined'
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </div>
                <div className='recaptcha-container'>
                    <Recaptcha captchaRef={captchaRef} />
                </div>
                <div>
                    <Button type='submit' size='small' variant='outlined'>
                        {constants.LOGIN_SCREEN.SEND_LINK}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ForgotPasswordForm;
