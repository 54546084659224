import React from 'react';
import './PlayerCard.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import constants from '../../../constants/constants';
import ageCalculator from '../../../generic actions/AgeCalculator';

function PlayerCard(props) {
    const { player, backButtonHandler, displayBackButton, displayEditButton, editButtonHandler } = props;
    const playerHasMatch = player.matches ? true : false;

    return (
        <>
            {displayBackButton && (
                <div className='back-button-row-table'>
                    <span onClick={backButtonHandler}>
                        <ArrowBackIcon className='arrow-back-button' />
                    </span>
                </div>
            )}
            <div className='card-container'>
                <div className='img-container'>
                    <img className='img-round' src={player.img} alt='player' />
                </div>
                <div className='player-name-container'>
                    <span>
                        {player.firstName} {player.lastName}, {ageCalculator(new Date(player.dateOfBirth))}
                    </span>
                    {displayEditButton && (
                        <span onClick={editButtonHandler} className='edit-span'>
                            {constants.PROFILE.EDIT}
                        </span>
                    )}
                </div>
                <div className='player-statistic-container'>
                    <div className='player-statistic-information'>
                        <span>{constants.TABLE.MATCHES} : </span>
                        <span className='number-of-games'>{playerHasMatch ? player.matches : 0}</span>
                    </div>
                    <div className='player-statistic-information'>
                        <span>{constants.TABLE.WINS} : </span>
                        <span className='number-of-games'>{player.wins ? player.wins : 0}</span>
                    </div>
                    <div className='player-statistic-information'>
                        <span>{constants.TABLE.LOSES} : </span>
                        <span className='number-of-games'>{playerHasMatch ? player.matches - player.wins : 0}</span>
                    </div>
                    <div className='player-statistic-information'>
                        <span>{constants.TABLE.WIN_STATS} : </span>
                        <span className='number-of-games'>{playerHasMatch ? `${((player.wins / player.matches) * 100).toFixed(0)}%` : `0%`}</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PlayerCard;
