import './ButtonSteps.css';
import Button from '@mui/material/Button';
import constants from '../../../../constants/constants';

function ButtonSteps(props) {
    return (
        <div className='button-steps-container'>
            <Button disabled={props.isContinueDisabled} variant='contained' onClick={props.handleNext} size='small'>
                {props.isFinalStep ? constants.ADD_GAME.FINISH : constants.ADD_GAME.CONTINUE}
            </Button>
            <Button disabled={props.isBackDisabled} onClick={props.handleBack} size='small'>
                {constants.ADD_GAME.BACK}
            </Button>
        </div>
    );
}

export default ButtonSteps;
