import * as React from 'react';
import './SetScoreComponent.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import constants from '../../../../../constants/constants';
import { useState } from 'react';

function SetScoreComponent(props) {
    const [playerOneSetValue, setPlayerOneSetValue] = useState('');
    const [playerTwoSetValue, setPlayerTwoSetValue] = useState('');

    let key = props.keyNumber;

    const handleChange = (value) => {
        if (value.name === `player-one-set-${key}`) {
            setPlayerOneSetValue(value.value);
        } else if (value.name === `player-two-set-${key}`) {
            setPlayerTwoSetValue(value.value);
        }
        props.handleChange(value);
    };
    return (
        <div key={key} className='set-score-container row'>
            <div className='col-4'>
                <span className='span-set-score-number'>
                    {constants.ADD_GAME.SET} {key}
                </span>
            </div>
            <div className='col-4'>
                <Select
                    value={playerOneSetValue}
                    size='small'
                    name={`player-one-set-${key}`}
                    onChange={(value) => handleChange(value.target)}
                    displayEmpty
                >
                    <MenuItem value='0'>0</MenuItem>
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                    <MenuItem value={'3'}>3</MenuItem>
                    <MenuItem value={'4'}>4</MenuItem>
                    <MenuItem value={'5'}>5</MenuItem>
                    <MenuItem value={'6'}>6</MenuItem>
                    <MenuItem value={'7'}>7</MenuItem>
                </Select>
            </div>
            <div className='col-4'>
                <Select
                    value={playerTwoSetValue}
                    size='small'
                    name={`player-two-set-${key}`}
                    onChange={(value) => handleChange(value.target)}
                    displayEmpty
                >
                    <MenuItem value='0'>0</MenuItem>
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                    <MenuItem value={'3'}>3</MenuItem>
                    <MenuItem value={'4'}>4</MenuItem>
                    <MenuItem value={'5'}>5</MenuItem>
                    <MenuItem value={'6'}>6</MenuItem>
                    <MenuItem value={'7'}>7</MenuItem>
                </Select>
            </div>
        </div>
    );
}

export default SetScoreComponent;
