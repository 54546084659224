import './SetScoreSection.css';
import { useState } from 'react';
import ButtonSteps from '../ButtonSteps/ButtonSteps';
import SetScoreComponent from './SetScoreComponent/SetScoreComponent';

function SetScoreSection(props) {
    const [setScore, setSetScore] = useState({});
    const numberOfSets = parseInt(props.playerOneScore) + parseInt(props.playerTwoScore);
    const [finishDisabled, setFinishDisabled] = useState(true);

    const handleFinish = () => {
        props.handleFinish(setScore);
    };

    const handleChange = (value) => {
        let tempSetScore = setScore;
        tempSetScore[value.name] = value;

        if (Object.keys(setScore).length === numberOfSets * 2) {
            let isAnyTieInput = false;
            for (let i = 1; i <= numberOfSets; i++) {
                let playerOneSetScore = tempSetScore[`player-one-set-${i}`].value;
                let playerTwoSetScore = tempSetScore[`player-two-set-${i}`].value;

                if (parseInt(playerOneSetScore) === parseInt(playerTwoSetScore)) {
                    isAnyTieInput = true;
                }
            }
            if (isAnyTieInput) {
                setFinishDisabled(true);
            } else {
                setFinishDisabled(false);
            }
        }
        setSetScore(tempSetScore);
    };

    const renderComponents = () => {
        const components = [];
        for (let i = 1; i <= numberOfSets; i++) {
            components.push(<SetScoreComponent key={i} handleChange={handleChange} keyNumber={i} />);
        }
        return components;
    };

    return (
        <div className='set-score-section-container'>
            <div className='row'>
                <div className='col-4'></div>
                <div className='col-4'>
                    <div>
                        <span className='span-player-name'>{props.playerInContext.firstName}</span>
                    </div>
                </div>
                <div className='col-4'>
                    <div>
                        <span className='span-player-name'>{props.playerOne.firstName}</span>
                    </div>
                </div>
            </div>
            {renderComponents()}
            <ButtonSteps
                isBackDisabled={false}
                isFinalStep={true}
                isContinueDisabled={finishDisabled}
                handleNext={handleFinish}
                handleBack={props.handleBack}
            />
        </div>
    );
}

export default SetScoreSection;
