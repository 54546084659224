import './RegistrationInput.css';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import TextField from '@mui/material/TextField';

function RegistrationInput(props) {
    const { input, inputsChangeHandler, setInput, maxLength, id, label, type, validation } = props;
    return (
        <div className='input-container'>
            <div className='input-label-container row'>
                <div className='col-1'>
                    {validation && (
                        <span>
                            <CheckSharpIcon className='registration-check-icon' />
                        </span>
                    )}
                </div>
                <div className='col-11'>
                    <span className='input-label-span'>{label}</span>
                    <span className='mandatory-filed-input'>*</span>
                </div>
            </div>
            <div className='text-field-container row'>
                <TextField
                    value={input}
                    inputProps={{
                        maxLength: maxLength,
                        style: { fontSize: '10px' },
                    }}
                    id={id}
                    variant='outlined'
                    onChange={inputsChangeHandler(setInput)}
                    size='small'
                    type={type}
                    autoComplete={type === 'password' ? 'new-password' : ''}
                />
            </div>
        </div>
    );
}

export default RegistrationInput;
