import React from 'react';
import './EditPlayerInfo.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function EditPlayerInfo(props) {
    const { backButtonHandler } = props;
    return (
        <div className='edit-card-container'>
            <div className='back-button-row-table'>
                <span onClick={backButtonHandler}>
                    <ArrowBackIcon className='arrow-back-button' />
                </span>
            </div>
        </div>
    );
}

export default EditPlayerInfo;
