import {
    SET_MATCHS_RESULTS,
    MATCH_SENDING_REQUEST,
    MATCH_SENDING_REQUEST_FAILURE,
    SET_SELECTED_MATCH,
    RELEASE_SELECTED_MATCH,
    MATCH_APPROVED_OR_REJECTED,
    SET_SELECTED_PLAYER_FOR_RESULTS,
    RELEASE_SELECTED_PLAYER_FOR_RESULTS,
    ADD_NEW_MATCH_FAILURE,
    ADD_NEW_MATCH_SUCCESS,
    ADD_NEW_MATCH_SENDING_REQUEST,
    GET_ALL_PLEAYE_MATCHES,
    SET_SELECTED_OPPONENT_FOR_RESULTS,
    RELEASE_SELECTED_OPPONENT_FOR_RESULTS,
} from '../constants/matchConstants';
import constants from '../../constants/constants';
import MatchServiceImpl from '../../service/MatchServiceImpl';
import ToastUtil from '../../utils/ToastUtils';

export const addMatch = (payload) => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);

        try {
            dispatch({
                type: ADD_NEW_MATCH_SENDING_REQUEST,
            });

            const addMatchRes = await MatchServiceImpl.addMatch(token, payload);
            if (addMatchRes?.data?.code) {
                dispatch({
                    type: ADD_NEW_MATCH_FAILURE,
                    error: addMatchRes?.data,
                });
                ToastUtil.error('שגיאה זמנית');
            } else {
                dispatch({
                    type: ADD_NEW_MATCH_SUCCESS,
                });
                ToastUtil.success('המשחק התווסף בהצלחה!');
            }
        } catch (error) {
            dispatch({
                type: ADD_NEW_MATCH_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};

export const getAllMatchsResults = () => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        try {
            dispatch({
                type: MATCH_SENDING_REQUEST,
            });

            const matchsResults = await MatchServiceImpl.getAllMatchsResults(token);
            if (matchsResults?.data?.code) {
                dispatch({
                    type: MATCH_SENDING_REQUEST_FAILURE,
                    error: matchsResults?.data,
                });
                ToastUtil.error('שגיאה זמנית');
            } else {
                dispatch({
                    type: SET_MATCHS_RESULTS,
                    matchsResults: matchsResults?.data,
                });
            }
        } catch (error) {
            dispatch({
                type: MATCH_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};

export const getAllMatchsResultsByPlayerId = (playerId) => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        try {
            dispatch({
                type: MATCH_SENDING_REQUEST,
            });

            const matchsResults = await MatchServiceImpl.getAllMatchsResultsByPlayerId(token, playerId);
            if (matchsResults?.data?.code) {
                dispatch({
                    type: MATCH_SENDING_REQUEST_FAILURE,
                    error: matchsResults?.data,
                });
                ToastUtil.error('שגיאה זמנית');
            } else {
                dispatch({
                    type: SET_MATCHS_RESULTS,
                    matchsResults: matchsResults?.data,
                });
            }
        } catch (error) {
            dispatch({
                type: MATCH_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};

export const getAllMatchsResultsByPlayerIdAndOpponentId = (playerId, opponentId) => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        try {
            dispatch({
                type: MATCH_SENDING_REQUEST,
            });

            const matchsResults = await MatchServiceImpl.getAllMatchsResultsByPlayerIdAndOpponentId(token, playerId, opponentId);
            if (matchsResults?.data?.code) {
                dispatch({
                    type: MATCH_SENDING_REQUEST_FAILURE,
                    error: matchsResults?.data,
                });
                ToastUtil.error('שגיאה זמנית');
            } else {
                dispatch({
                    type: SET_MATCHS_RESULTS,
                    matchsResults: matchsResults?.data,
                });
            }
        } catch (error) {
            dispatch({
                type: MATCH_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};

export const getAllPlayerMatches = (playerId) => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        if (token) {
            try {
                dispatch({
                    type: MATCH_SENDING_REQUEST,
                });
                const playerMatches = await MatchServiceImpl.getAllPlayerMatches(token, playerId);
                if (playerMatches?.data?.code) {
                    dispatch({
                        type: MATCH_SENDING_REQUEST_FAILURE,
                        error: playerMatches?.data,
                    });
                    ToastUtil.error('שגיאה זמנית');
                } else {
                    dispatch({
                        type: GET_ALL_PLEAYE_MATCHES,
                        playerMatches: playerMatches?.data,
                    });
                }
            } catch (error) {
                dispatch({
                    type: MATCH_SENDING_REQUEST_FAILURE,
                    error: error?.response ? error?.response?.data : error,
                });
                ToastUtil.error('שגיאה זמנית');
            }
        }
    };
};

export const approveOrRejectMatchByMatchId = (matchId, action) => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        try {
            dispatch({
                type: MATCH_SENDING_REQUEST,
            });
            if (action === constants.ADD_GAME.ACTION_APPROVE) {
                await MatchServiceImpl.approveMatchByMatchId(token, matchId);
            } else {
                await MatchServiceImpl.rejectMatchByMatchId(token, matchId);
            }

            dispatch({
                type: MATCH_APPROVED_OR_REJECTED,
            });
            ToastUtil.success('פעולה בוצעה בהצלחה!');
        } catch (error) {
            dispatch({
                type: MATCH_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};

export const setSelectedPlayerForResults = (player) => {
    return {
        type: SET_SELECTED_PLAYER_FOR_RESULTS,
        selectedPlayerForResults: player,
    };
};

export const releaseSelectedPlayerForResults = () => {
    return {
        type: RELEASE_SELECTED_PLAYER_FOR_RESULTS,
    };
};

export const setSelectedOpponentForResults = (player) => {
    return {
        type: SET_SELECTED_OPPONENT_FOR_RESULTS,
        selectedOpponentForResults: player,
    };
};

export const releaseSelectedOpponentForResults = () => {
    return {
        type: RELEASE_SELECTED_OPPONENT_FOR_RESULTS,
    };
};

export const setSelectedMatch = (match) => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        try {
            dispatch({
                type: MATCH_SENDING_REQUEST,
            });
            const setPoints = await MatchServiceImpl.getSetsByMatchId(token, match.matchId);
            dispatch({
                type: SET_SELECTED_MATCH,
                match: match,
                setPoints: setPoints?.data,
            });
        } catch (error) {
            dispatch({
                type: MATCH_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};

export const releaseSelectedMatch = () => {
    return {
        type: RELEASE_SELECTED_MATCH,
    };
};
