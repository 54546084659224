import axios from 'axios';

export default class ServiceClass {
    constructor(fetcherBaseURL, prefix) {
        let baseApiUrl = fetcherBaseURL;
        baseApiUrl += `${prefix}`;
        this.axios = axios.create({
            baseURL: baseApiUrl,
        });
    }

    get(url, headers, params) {
        return this.axios.get(url, { params, headers });
    }
    post(url, body, headers, params) {
        return this.axios.post(url, body, { params, headers });
    }
    put(url, body, headers) {
        return this.axios.put(url, body, { headers });
    }
    delete(url, headers) {
        return this.axios.delete(url, { headers });
    }
}
