import {
    SET_MATCHS_RESULTS,
    MATCH_SENDING_REQUEST,
    MATCH_SENDING_REQUEST_FAILURE,
    SET_SELECTED_MATCH,
    RELEASE_SELECTED_MATCH,
    MATCH_APPROVED_OR_REJECTED,
    SET_SELECTED_PLAYER_FOR_RESULTS,
    RELEASE_SELECTED_PLAYER_FOR_RESULTS,
    ADD_NEW_MATCH_SENDING_REQUEST,
    ADD_NEW_MATCH_FAILURE,
    ADD_NEW_MATCH_SUCCESS,
    GET_ALL_PLEAYE_MATCHES,
    RELEASE_SELECTED_OPPONENT_FOR_RESULTS,
    SET_SELECTED_OPPONENT_FOR_RESULTS,
} from '../constants/matchConstants';
import { LOGOUT } from '../constants/authConstants';

const initialState = {
    playerMatches: {},
    matchsResults: [],
    selectedPlayerForResults: null,
    selectedOpponentForResults: null,
    selectedMatch: null,
    matchApprovedOrRejectedFlag: false,
    isLoading: false,
    error: null,
};

const playerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NEW_MATCH_SENDING_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case ADD_NEW_MATCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        case ADD_NEW_MATCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        case MATCH_SENDING_REQUEST:
            return { ...state, isLoading: true, error: null };
        case MATCH_SENDING_REQUEST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case SET_MATCHS_RESULTS:
            return {
                ...state,
                matchsResults: action.matchsResults,
                isLoading: false,
                error: null,
            };
        case MATCH_APPROVED_OR_REJECTED:
            return {
                ...state,
                matchApprovedOrRejectedFlag: !state.matchApprovedOrRejectedFlag,
                isLoading: false,
                error: null,
            };

        case GET_ALL_PLEAYE_MATCHES:
            return {
                ...state,
                playerMatches: action.playerMatches,
                isLoading: false,
                error: null,
            };
        case SET_SELECTED_MATCH:
            return {
                ...state,
                selectedMatch: {
                    ...action.match,
                    setPoints: action.setPoints,
                },
                isLoading: false,
                error: null,
            };
        case RELEASE_SELECTED_MATCH:
            return {
                ...state,
                selectedMatch: null,
            };
        case SET_SELECTED_PLAYER_FOR_RESULTS:
            return {
                ...state,
                selectedPlayerForResults: action.selectedPlayerForResults,
            };
        case SET_SELECTED_OPPONENT_FOR_RESULTS:
            return {
                ...state,
                selectedOpponentForResults: action.selectedOpponentForResults,
            };
        case RELEASE_SELECTED_PLAYER_FOR_RESULTS:
            return {
                ...state,
                selectedPlayerForResults: null,
            };
        case RELEASE_SELECTED_OPPONENT_FOR_RESULTS:
            return {
                ...state,
                selectedOpponentForResults: null,
            };
        case LOGOUT:
            return {
                ...state,
                playerMatches: {},
                matchsResults: [],
                selectedPlayerForResults: null,
                selectedOpponentForResults: null,
                selectedMatch: null,
                matchApprovedOrRejectedFlag: false,
                isLoading: false,
                error: null,
            };
        default:
            return state;
    }
};

export default playerReducer;
