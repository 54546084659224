import React from 'react';
import './Match.css';
import { useSelector, useDispatch } from 'react-redux';
import * as matchActions from '../../../redux/actions/matchActions';
import MatchHeader from '../../../components/mobile/MatchHeader/MatchHeader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MatchSetPoints from '../../../components/mobile/MatchSetPoints/MatchSetPoints';

function Match() {
    const { selectedMatch } = useSelector((state) => state.match);

    const dispatch = useDispatch();

    const backButtonHandler = () => {
        dispatch(matchActions.releaseSelectedMatch());
    };

    return (
        <div>
            <div className='back-button-row'>
                <span onClick={backButtonHandler}>
                    <ArrowBackIcon className='arrow-back-button' />
                </span>
            </div>
            <div className='match-container'>
                <div className='match-header-row'>
                    <MatchHeader matchDetails={selectedMatch} />
                </div>
                <div className='match-set-row'>
                    <MatchSetPoints selectedMatch={selectedMatch} />
                </div>
            </div>
        </div>
    );
}

export default Match;
