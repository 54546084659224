import { GET_CITIES_SENDING_REQUEST, GET_CITIES_REQUEST_FAILURE, GET_CITIES_REQUEST_SUCCESS } from '../constants/cityConstants';
import AuthServiceImpl from '../../service/AuthServiceImpl';
import ToastUtil from '../../utils/ToastUtils';

export const getAllCities = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: GET_CITIES_SENDING_REQUEST,
            });
            const cities = await AuthServiceImpl.getAllCities();
            if (cities?.data?.code) {
                dispatch({
                    type: GET_CITIES_REQUEST_FAILURE,
                    error: cities?.data,
                });
                ToastUtil.error('שגיאה זמנית');
            } else {
                dispatch({
                    type: GET_CITIES_REQUEST_SUCCESS,
                    cities: cities?.data,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_CITIES_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};
