export const MATCH_APPROVED_OR_REJECTED = 'MATCH_APPROVED_OR_REJECTED';
export const SET_MATCHS_RESULTS = 'SET_MATCHS_RESULTS';
export const SET_SELECTED_MATCH = 'SET_SELECTED_MATCH';
export const RELEASE_SELECTED_MATCH = 'RELEASE_SELECTED_MATCH';
export const SET_SELECTED_PLAYER_FOR_RESULTS = 'SET_SELECTED_PLAYER_FOR_RESULTS';
export const RELEASE_SELECTED_PLAYER_FOR_RESULTS = 'RELEASE_SELECTED_PLAYER_FOR_RESULTS';
export const MATCH_SENDING_REQUEST = 'SENDING_REQUEST';
export const MATCH_SENDING_REQUEST_FAILURE = 'SENDING_REQUEST_FAILURE';
export const ADD_NEW_MATCH_SENDING_REQUEST = 'ADD_NEW_MATCH_SENDING_REQUEST';
export const ADD_NEW_MATCH_SUCCESS = 'ADD_NEW_MATCH_SUCCESS';
export const ADD_NEW_MATCH_FAILURE = 'ADD_NEW_MATCH_FAILURE';
export const GET_ALL_PLEAYE_MATCHES = 'GET_ALL_PLEAYE_MATCHES';
export const RELEASE_SELECTED_OPPONENT_FOR_RESULTS = 'RELEASE_SELECTED_OPPONENT_FOR_RESULTS';
export const SET_SELECTED_OPPONENT_FOR_RESULTS = 'SET_SELECTED_OPPONENT_FOR_RESULTS';
