import './CustomModal.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

function CustomModal(props) {
    const primaryButton = () => {
        props.handlePrimaryButton(props.modalInformation.action);
    };

    const linkButton = () => {
        props.handleLinkButton();
    };

    return (
        <div className='modal-container'>
            <Modal open={true}>
                <Box className='box-modal-container'>
                    <div className='box-modal-header'>
                        <span>{props.modalInformation.primaryButtonText}</span>
                        <CloseIcon className='logout-modal-close-icon' onClick={linkButton} />
                    </div>
                    <div className='approval-modal-button-row row'>
                        <div className='approval-modal-button-column col-6'>
                            <Button className='approve-game-button-custom' onClick={primaryButton} size='small' variant='outlined'>
                                {props.modalInformation.primaryButtonText}
                            </Button>
                        </div>
                        <div className='approval-modal-button-column col-6'>
                            <Link component='button' variant='body2' onClick={linkButton}>
                                {props.modalInformation.linkButtonText}
                            </Link>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default CustomModal;
