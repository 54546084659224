import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as authActions from '../../../redux/actions/authActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import constants from '../../../constants/constants';
import './ResetPasswordForm.css';
import Logo from '../Logo/Logo';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RoutesUrl } from '../../../router/RoutesUrls';

const ResetPasswordForm = () => {
    const [token, setToken] = useState('');

    const [passwordResetSuccessfully, setPasswordResetSuccessfully] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validationSchema = yup.object().shape({
        password: yup.string().required(constants.LOGIN_SCREEN.REQUIRE_FILED),
        rePassword: yup
            .string()
            .required(constants.LOGIN_SCREEN.REQUIRE_FILED)
            .oneOf([yup.ref('password')], constants.LOGIN_SCREEN.INVALID_RE_PASSWORD),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            rePassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            formik.resetForm();
            setPasswordResetSuccessfully(
                await dispatch(
                    authActions.resetPassword({
                        password: values.password,
                        token,
                    })
                )
            );
        },
    });

    const navigationHomeHandler = () => {
        navigate(RoutesUrl.welcome);
    };

    useEffect(() => {
        const getTokenQueryParam = async (tokenParam) => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const tokenValue = urlSearchParams.get(tokenParam);
            return tokenValue;
        };

        const fetchToken = async () => {
            setToken(await getTokenQueryParam('token'));
        };

        fetchToken();
    }, []);

    return (
        <div className='reset-password-container'>
            <div className='reset-password-logo-container'>
                <Logo />
            </div>
            <div className='reset-password-form-container'>
                {passwordResetSuccessfully ? (
                    <div>
                        <div>
                            <span>{constants.LOGIN_SCREEN.RESET_SUCCESFULLY}</span>
                        </div>
                        <div className='navigation-row-container'>
                            <span onClick={navigationHomeHandler} className='navigate-login-span'>
                                {constants.LOGIN_SCREEN.NAVIGATE_HOME}
                            </span>
                        </div>
                    </div>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <div className='reset-password-row'>
                            <span>{constants.LOGIN_SCREEN.RESET_PASSWORD}</span>
                        </div>
                        <div className='reset-password-row'>
                            <TextField
                                size='small'
                                type='password'
                                value={formik.values.password}
                                onChange={formik.handleChange('password')}
                                placeholder={constants.LOGIN_SCREEN.PASSWORD}
                                variant='outlined'
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </div>
                        <div className='reset-password-row'>
                            <TextField
                                size='small'
                                type='password'
                                value={formik.values.rePassword}
                                onChange={formik.handleChange('rePassword')}
                                placeholder={constants.LOGIN_SCREEN.RE_ENTER_PASSWORD}
                                variant='outlined'
                                error={formik.touched.rePassword && Boolean(formik.errors.rePassword)}
                                helperText={formik.touched.rePassword && formik.errors.rePassword}
                            />
                        </div>

                        <div className='reset-password-row'>
                            <Button type='submit' size='small' variant='outlined'>
                                {constants.LOGIN_SCREEN.RESET_PASSWORD}
                            </Button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ResetPasswordForm;
