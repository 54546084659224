import constants from '../../../constants/constants';
import './Logo.css';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';

function Logo() {
    return (
        <div className='logo-container'>
            <div>
                <SportsTennisIcon className='icon-custom' />
            </div>
            <div>
                <span className='title-text'>{constants.LOGO.TITLE}</span>
            </div>
            <div>
                <span className='sub-title-text'>{constants.LOGO.SUB_TITLE}</span>
            </div>
        </div>
    );
}

export default Logo;
