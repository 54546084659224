import React from 'react';
import './MatchSetPoints.css';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import constants from '../../../constants/constants';
import MatchSummaryBox from '../MatchSummaryBox/MatchSummaryBox';

function MatchSetPoints(props) {
    const {
        selectedMatch: { setPoints, playerOne, playerTwo },
    } = props;

    const setOne = setPoints.find((match) => match.setNumber === '1');
    const setTwo = setPoints.find((match) => match.setNumber === '2');
    const setThree = setPoints.find((match) => match.setNumber === '3');

    return (
        <>
            <div className='match-set-points-container'>
                <div className='row'>
                    <div className='col-6'>
                        <span className='player-span-text'>{playerOne.name}</span>
                    </div>
                    <div className='col-2'>
                        <span className='player-score-span-text'>{setOne ? setOne.playerOneScore : ''}</span>
                    </div>
                    <div className='col-2'>
                        <span className='player-score-span-text'>{setTwo ? setTwo.playerOneScore : ''}</span>
                    </div>
                    <div className='col-2'>
                        <span className='player-score-span-text'>{setThree ? setThree.playerOneScore : ''}</span>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>vs</div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <span className='player-span-text'>{playerTwo.name}</span>
                    </div>
                    <div className='col-2'>
                        <span className='player-score-span-text'>{setOne ? setOne.playerTwoScore : ''}</span>
                    </div>
                    <div className='col-2'>
                        <span className='player-score-span-text'>{setTwo ? setTwo.playerTwoScore : ''}</span>
                    </div>
                    <div className='col-2'>
                        <span className='player-score-span-text'>{setThree ? setThree.playerTwoScore : ''}</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MatchSetPoints;
