import './WizardSteps.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import constants from '../../../constants/constants';
import SelectPlayerSection from './SelectPlayerSection/SelectPlayerSection';
import SelectcourtSection from './SelectCourtSection/SelectCourtSection';
import SelectDateSection from './SelectDateSection/SelectDateSection';
import MatchScoreSection from './MatchScoreSection/MatchScoreSection';
import SetScoreSection from './SetScoreSection/SetScoreSection';
import { useDispatch } from 'react-redux';
import * as matchActions from '../../../redux/actions/matchActions';
import Loader from '../loader/Loader';

function WizardSteps() {
    const [player, setPlayer] = useState({ id: '' });
    const [courtId, setCourtId] = useState('');
    const [date, setDate] = useState(new Date());
    const [playerOneScore, setPlayerOneScore] = useState('');
    const [playerTwoScore, setPlayerTwoScore] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [isFinished, setIsFinished] = useState(false);

    const { player: playerInContext, playersList } = useSelector((state) => state.player);
    const { isGuest } = useSelector((state) => state.auth);
    const { isLoading, error } = useSelector((state) => state.match);
    const { courts } = useSelector((state) => state.court);

    const dispatch = useDispatch();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFinish = async (setScore) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        const payload = {
            playerOneId: playerInContext.playerId,
            playerTwoId: player.id,
            courtId: courtId,
            date: date,
            playerOneScore: playerOneScore,
            playerTwoScore: playerTwoScore,
            setScore: setScore,
        };

        dispatch(matchActions.addMatch(payload));
        setIsFinished(true);
    };

    const courtSelectedIdChanged = (courtId) => {
        setCourtId(courtId);
    };

    const matchScoreChanged = (value) => {
        if (value.name === 'playerOneGroup') {
            setPlayerOneScore(value.value);
        } else if (value.name === 'playerTwoGroup') {
            setPlayerTwoScore(value.value);
        }
    };

    const playerSelectedIdChanged = (player) => {
        setPlayer(player);
    };

    const dateSelectedChanged = (selectedDate) => {
        setDate(selectedDate);
    };

    const steps = [
        {
            label: constants.ADD_GAME.SELECT_PLAYERL,
            description: (
                <>
                    <SelectPlayerSection
                        handleBack={handleBack}
                        handleNext={handleNext}
                        playersList={playersList}
                        playerInContext={playerInContext}
                        playerSelectedIdChanged={playerSelectedIdChanged}
                        playerId={player?.id}
                    />
                </>
            ),
        },
        {
            label: constants.ADD_GAME.SELECT_COURT,
            description: (
                <>
                    <SelectcourtSection
                        handleBack={handleBack}
                        handleNext={handleNext}
                        courts={courts}
                        courtSelectedIdChanged={courtSelectedIdChanged}
                        courtId={courtId}
                    />
                </>
            ),
        },
        {
            label: constants.ADD_GAME.SELECT_DATE,
            description: (
                <>
                    <SelectDateSection handleBack={handleBack} handleNext={handleNext} date={date} dateSelectedChanged={dateSelectedChanged} />
                </>
            ),
        },
        {
            label: constants.ADD_GAME.MATCH_SCORE,
            description: (
                <>
                    <MatchScoreSection
                        handleBack={handleBack}
                        handleNext={handleNext}
                        playerOne={player}
                        playerInContext={playerInContext}
                        playerOneScore={playerOneScore}
                        playerTwoScore={playerTwoScore}
                        matchScoreChanged={matchScoreChanged}
                    />
                </>
            ),
        },
        {
            label: constants.ADD_GAME.SET_SCORE,
            description: (
                <>
                    <SetScoreSection
                        playerOne={player}
                        handleBack={handleBack}
                        handleFinish={handleFinish}
                        playerInContext={playerInContext}
                        playerTwoScore={playerTwoScore}
                        playerOneScore={playerOneScore}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <div className={`add-match-form-container ${isLoading ? 'wizard-steps-blur-background' : ''}`}>
                {isGuest ? (
                    <div>
                        <div>
                            <span>{constants.AUTH.ADD_MATCH_NOT_AVAILABLE}</span>
                        </div>
                        <div>
                            <span>{constants.AUTH.IS_GUEST}</span>
                        </div>
                    </div>
                ) : (
                    <Stepper activeStep={activeStep} orientation='vertical'>
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel>{step.label}</StepLabel>
                                <StepContent>{step.description}</StepContent>
                            </Step>
                        ))}
                        {isFinished && (
                            <div className={!error ? 'success-game-row row' : 'failure-game-row row'}>
                                <div className='col-12'>
                                    <span>{!error ? constants.ADD_GAME.SUCCESS_MESSAGE : constants.ADD_GAME.ADD_GAME_FAILED}</span>
                                </div>
                                <div className='col-12'>
                                    <span>{!error ? constants.ADD_GAME.MORE_DETAILS : constants.ADD_GAME.TRY_AGAIN_LATER}</span>
                                </div>
                            </div>
                        )}
                    </Stepper>
                )}
            </div>
            {isLoading && (
                <div className='wizard-steps-loader-container'>
                    <Loader />
                </div>
            )}
        </>
    );
}

export default WizardSteps;
