import ServiceClass from './ServiceClass';
import ClientConfig from '../config';

class MatchServiceImpl extends ServiceClass {
    async getAllMatchsResults(token) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.get('/api/matchManagement/match/results', headers);
    }
    async getAllMatchsResultsByPlayerId(token, playerId) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.get(`/api/matchManagement/match/results/${playerId}`, headers);
    }
    async getAllMatchsResultsByPlayerIdAndOpponentId(token, playerId, opponentId) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.get(`/api/matchManagement/match/results/players`, headers, { playerId, opponentId });
    }
    async getSetsByMatchId(token, matchId) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.get(`/api/matchManagement/match/sets/${matchId}`, headers);
    }

    async addMatch(token, payload) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.post(`/api/matchManagement/match/addMatch`, payload, headers);
    }
    async approveMatchByMatchId(token, matchId) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.post(`/api/matchManagement/match/approval/${matchId}`, {}, headers);
    }
    async rejectMatchByMatchId(token, matchId) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.post(`/api/matchManagement/match/reject/${matchId}`, {}, headers);
    }
    async getAllPlayerMatches(token, playerId) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.get(`/api/matchManagement/match/playerMatches/${playerId}`, headers);
    }
}

export default new MatchServiceImpl(ClientConfig.apiBaseHost, '');
