import React, { useEffect, useState } from 'react';
import './PlayerProfile.css';
import { useSelector, useDispatch } from 'react-redux';
import PlayerCard from '../../../components/mobile/PlayerCard/PlayerCard';
import * as playerActions from '../../../redux/actions/playerAction';
import * as matchActions from '../../../redux/actions/matchActions';
import constants from '../../../constants/constants';
import ServerError from '../../../components/mobile/ServerError/ServerError';
import ProfileTabs from '../../../components/mobile/ProfileTabs/ProfileTabs';
import MatchSummaryBox from '../../../components/mobile/MatchSummaryBox/MatchSummaryBox';
import EditPlayerInfo from '../../../components/mobile/EditPlayerInfo/EditPlayerInfo';

function PlayerProfile() {
    const [isEditMode, setIsEditMode] = useState(false);

    const { player, isLoading: playerIsLoading, error: playerError } = useSelector((state) => state.player);
    const { isGuest } = useSelector((state) => state.auth);
    const { playerMatches, matchApprovedOrRejectedFlag, isLoading: matchIsLoading, error: matchError } = useSelector((state) => state.match);
    const {
        matchesToApproveByMe = [],
        matchesWaitingForApproval = [],
        rejectedMatchesByAnotherPlayer = [],
        rejectedMatchesByMe = [],
    } = playerMatches;
    const [activeTab, setActiveTab] = useState(constants.TABS.MATCHES_TO_APPROVE_ID);

    const isLoading = playerIsLoading || matchIsLoading;
    const error = playerError || matchError;
    const dispatch = useDispatch();

    const fetchPlayer = async () => {
        await dispatch(playerActions.setPlayer());
    };

    const changeTabHandler = (tabId) => {
        if (tabId !== activeTab) {
            setActiveTab(tabId);
        }
    };

    const changeEditModeHandler = () => {
        setIsEditMode(!isEditMode);
    };

    useEffect(() => {
        if (!isGuest) {
            dispatch(matchActions.getAllPlayerMatches(player.playerId));
            fetchPlayer();
        }
    }, [matchApprovedOrRejectedFlag]);

    return (
        <div className='profile-container'>
            {error && !isLoading && <ServerError reload={fetchPlayer} />}
            {!error && !isLoading && !isGuest && (
                <>
                    {isEditMode ? (
                        <EditPlayerInfo backButtonHandler={changeEditModeHandler} />
                    ) : (
                        <PlayerCard
                            player={player}
                            backButtonHandler={() => {}}
                            displayBackButton={false}
                            displayEditButton={true}
                            editButtonHandler={changeEditModeHandler}
                        />
                    )}
                    <ProfileTabs activeTab={activeTab} changeTabHandler={changeTabHandler} />
                    <div className='player-profile-approval-game-container row'>
                        {activeTab === constants.TABS.MATCHES_TO_APPROVE_ID &&
                            (matchesToApproveByMe?.length > 0 ? (
                                matchesToApproveByMe?.map((match, i) => {
                                    return <MatchSummaryBox key={i} match={match} showActions={true} />;
                                })
                            ) : (
                                <span className='no-maches-to-show-span'>{constants.TABS.NO_MATCHES_TO_APPROVE}</span>
                            ))}
                        {activeTab === constants.TABS.MATCHES_ARE_WAITING_ID &&
                            (matchesWaitingForApproval?.length > 0 ? (
                                matchesWaitingForApproval?.map((match, i) => {
                                    return <MatchSummaryBox key={i} match={match} showActions={false} />;
                                })
                            ) : (
                                <span className='no-maches-to-show-span'>{constants.TABS.NO_MATCHES_WAITING_FOR_APPROVE}</span>
                            ))}
                        {activeTab === constants.TABS.MATCHES_REJECTED_MATCHES_ID &&
                            (rejectedMatchesByAnotherPlayer?.length > 0 ? (
                                rejectedMatchesByAnotherPlayer?.map((match, i) => {
                                    return <MatchSummaryBox key={i} match={match} showActions={false} />;
                                })
                            ) : (
                                <span className='no-maches-to-show-span'>{constants.TABS.NO_REJECTED_MATCHES}</span>
                            ))}
                        {activeTab === constants.TABS.MATCHES_REJECTED_MATCHES_BY_ME_ID &&
                            (rejectedMatchesByMe?.length > 0 ? (
                                rejectedMatchesByMe?.map((match, i) => {
                                    return <MatchSummaryBox key={i} match={match} showActions={false} />;
                                })
                            ) : (
                                <span className='no-maches-to-show-span'>{constants.TABS.NO_REJECTED_MATCHES}</span>
                            ))}
                    </div>
                </>
            )}
            {!error && isGuest && (
                <div className='guest-container'>
                    <div>
                        <span>{constants.AUTH.PROFILE_NOT_AVAILABLE}</span>
                    </div>
                    <div>
                        <span>{constants.AUTH.IS_GUEST}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PlayerProfile;
