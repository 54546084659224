import { Triangle } from 'react-loader-spinner';
import './Loader.css';

const Loader = () => {
    const primaryTextColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-text-color').trim();

    return (
        <div className='loader-spinner-container'>
            <Triangle height={100} width={100} radius={5} color={primaryTextColor} visible={true} />
        </div>
    );
};

export default Loader;
