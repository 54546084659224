import * as React from "react";
import "./MatchScoreSection.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import { useEffect } from "react";
import ButtonSteps from "../ButtonSteps/ButtonSteps";

function MatchScoreSection(props) {
  const [playerOneScore, setPlayerOneScore] = useState("");
  const [playerTwoScore, setPlayerTwoScore] = useState("");
  const [continueIsDisabled, setContinueIsDisabled] = useState(true);

  const setPlayersScore = (value) => {
    if (value.name === "playerOneGroup") {
      setPlayerOneScore(value.value);
    } else if (value.name === "playerTwoGroup") {
      setPlayerTwoScore(value.value);
    }
    props.matchScoreChanged(value);
  };

  useEffect(() => {
    setPlayerOneScore(props.playerOneScore);
    setPlayerTwoScore(props.playerTwoScore);
    if (props.playerOneScore !== "" && props.playerTwoScore !== "") {
      setContinueIsDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (
      playerOneScore === playerTwoScore ||
      playerOneScore === "" ||
      playerTwoScore === ""
    ) {
      setContinueIsDisabled(true);
    } else {
      setContinueIsDisabled(false);
    }
  }, [playerOneScore, playerTwoScore]);

  return (
    <div>
      <div className="player-name-row">
        <span className="span-player-name">
          {props.playerInContext.firstName}
        </span>
      </div>
      <FormControl>
        <RadioGroup
          onChange={(value) => setPlayersScore(value.target)}
          row
          name="playerOneGroup"
          defaultValue="top"
        >
          <FormControlLabel
            className="form-button-custom"
            value="0"
            checked={playerOneScore === "0" ? true : false}
            control={<Radio />}
            label="0"
            labelPlacement="top"
          />
          <FormControlLabel
            className="form-button-custom"
            value="1"
            checked={playerOneScore === "1" ? true : false}
            control={<Radio />}
            label="1"
            labelPlacement="top"
          />
          <FormControlLabel
            className="form-button-custom"
            value="2"
            checked={playerOneScore === "2" ? true : false}
            control={<Radio />}
            label="2"
            labelPlacement="top"
          />
          <FormControlLabel
            className="form-button-custom"
            value="3"
            checked={playerOneScore === "3" ? true : false}
            control={<Radio />}
            label="3"
            labelPlacement="top"
          />
        </RadioGroup>
      </FormControl>
      <div className="player-name-row">
        <span className="span-player-name">{props.playerOne.firstName}</span>
      </div>
      <FormControl>
        <RadioGroup
          onChange={(value) => setPlayersScore(value.target)}
          row
          name="playerTwoGroup"
          defaultValue="top"
        >
          <FormControlLabel
            className="form-button-custom"
            value="0"
            checked={playerTwoScore === "0" ? true : false}
            control={<Radio />}
            label="0"
            labelPlacement="top"
          />
          <FormControlLabel
            className="form-button-custom"
            value="1"
            checked={playerTwoScore === "1" ? true : false}
            control={<Radio />}
            label="1"
            labelPlacement="top"
          />
          <FormControlLabel
            className="form-button-custom"
            value="2"
            checked={playerTwoScore === "2" ? true : false}
            control={<Radio />}
            label="2"
            labelPlacement="top"
          />
          <FormControlLabel
            className="form-button-custom"
            value="3"
            checked={playerTwoScore === "3" ? true : false}
            control={<Radio />}
            label="3"
            labelPlacement="top"
          />
        </RadioGroup>
      </FormControl>
      <ButtonSteps
        isBackDisabled={false}
        isFinalStep={false}
        isContinueDisabled={continueIsDisabled}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
      />
    </div>
  );
}

export default MatchScoreSection;
