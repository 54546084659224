import React, { useState } from 'react';
import './MatchSummaryBox.css';
import constants from '../../../constants/constants';
import dateFormatter from '../../../generic actions/DateFormatter';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import * as matchActions from '../../../redux/actions/matchActions';
import { useDispatch } from 'react-redux';
import CustomModal from '../CustomModal/CustomModal';

function MatchSummaryBox(props) {
    const { match, showActions } = props;

    const [showModal, setShowModal] = useState(false);
    const [modalInformation, setModalInformation] = useState({
        linkButtonText: constants.ADD_GAME.WAIT,
    });

    const dispatch = useDispatch();

    const handleApproveClickIcon = () => {
        setModalInformation({
            ...modalInformation,
            primaryButtonText: constants.ADD_GAME.APPROVED,
            action: constants.ADD_GAME.ACTION_APPROVE,
            title: constants.ADD_GAME.APPROVED,
        });
        setShowModal(true);
    };

    const handleRejectClickIcon = () => {
        setModalInformation({
            ...modalInformation,
            primaryButtonText: constants.ADD_GAME.REJECT,
            action: constants.ADD_GAME.ACTION_REJECT,
            title: constants.ADD_GAME.REJECT,
        });
        setShowModal(true);
    };

    const handleLinkButton = () => {
        setShowModal(false);
    };
    const handlePrimaryButton = async (action) => {
        dispatch(matchActions.approveOrRejectMatchByMatchId(match.matchId, action));
        setShowModal(false);
    };

    const setOne = match.matchSets.find((match) => match.setNumber === '1');
    const setTwo = match.matchSets.find((match) => match.setNumber === '2');
    const setThree = match.matchSets.find((match) => match.setNumber === '3');
    return (
        <div className='rejected-match-container'>
            <div className='match-information-container row'>
                <span className='match-information-span-text'>
                    {dateFormatter(match.date)}, {match.courtName}
                </span>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <span className='player-span-text'>
                        {match.playerOneFirstName} {match.playerOneLastName}
                    </span>
                </div>
                <div className='col-2'>
                    <span className='player-score-span-text'>{setOne ? setOne.playerOneScore : ''}</span>
                </div>
                <div className='col-2'>
                    <span className='player-score-span-text'>{setTwo ? setTwo.playerOneScore : ''}</span>
                </div>
                <div className='col-2'>
                    <span className='player-score-span-text'>{setThree ? setThree.playerOneScore : ''}</span>
                </div>
            </div>
            <div className='row'>
                <div className='vs-text col-6'>VS</div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <span className='player-span-text'>
                        {match.playerTwoFirstName} {match.playerTwoLastName}
                    </span>
                </div>
                <div className='col-2'>
                    <span className='player-score-span-text'>{setOne ? setOne.playerTwoScore : ''}</span>
                </div>
                <div className='col-2'>
                    <span className='player-score-span-text'>{setTwo ? setTwo.playerTwoScore : ''}</span>
                </div>
                <div className='col-2'>
                    <span className='player-score-span-text'>{setThree ? setThree.playerTwoScore : ''}</span>
                </div>
            </div>
            {showActions && (
                <div className='row'>
                    <div className='actions-column col-6'>
                        <CheckCircleOutlineIcon
                            className='custom-approval-reject-button'
                            onClick={() => handleApproveClickIcon()}
                            style={{ color: '#00B700' }}
                        />
                    </div>
                    <div className='actions-column col-6'>
                        <HighlightOffIcon
                            className='custom-approval-reject-button'
                            onClick={() => handleRejectClickIcon()}
                            style={{ color: '#D10000' }}
                        />
                    </div>
                </div>
            )}
            {showModal && (
                <CustomModal modalInformation={modalInformation} handleLinkButton={handleLinkButton} handlePrimaryButton={handlePrimaryButton} />
            )}
        </div>
    );
}

export default MatchSummaryBox;
