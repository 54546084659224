const httpFetch = {
    HOST: 'http://localhost:4000',
};

const matchDetails = {
    SET: 'מערכה',
};

const table = {
    PLACE: 'מקום',
    PLAYER_NAME: 'שחקן',
    MATCHES: 'משחקים',
    MOBILE_MATCHES: `מש'`,
    WINS: 'ניצחונות',
    MOBILE_WINS: `נצ'`,
    LOSES: 'הפסדים',
    MOBILE_LOSES: `הפ'`,
    WIN_STATS: 'אחוזי ניצחון',
    MOBILE_WIN_STATS: `אחוזי נצ'`,
};

const errorScreen = {
    OOPS: 'אופס! משהו השתבש',
    RELOAD: 'טען שוב',
};

const auth = {
    CREATE_ACCOUNT: 'יצירת חשבון',
    LOGIN: 'התחברות',
    GUEST: 'התחבר כאורח',
    IS_GUEST: 'אינך משתמש רשום',
    PROFILE_NOT_AVAILABLE: 'פרופיל אינו זמין',
    ADD_MATCH_NOT_AVAILABLE: 'הוספת משחק אינו זמין',
    LOGIN_VIEW: 'login',
    REGISTER_VIEW: 'register',
    FORGOT_PASSWORD_VIEW: 'forgotPassword',
};

const results = {
    NO_RESULTS: 'לא נמצאו משחקים',
};

const login = {
    WAITING_FOR_APPROVAL_ERROR: 'משתמש ממתין לאישור',
    NOT_FOUND_ERROR: 'טלפון או סיסמא לא נכונים',
    SERVER_ERROR: 'שגיאה זמנית - אנא נסה שנית מאוחר יותר',
    LOGIN: 'התחברות',
    EMAIL: 'דואר אלקטרוני',
    USER_NAME: 'מספר טלפון',
    PASSWORD: 'סיסמא',
    RE_ENTER_PASSWORD: 'הקלד סיסמא שוב',
    REQUIRE_FILED: 'שדה חובה',
    NAVIGATE_HOME: 'עבור לדף התחברות',
    SENT_SUCCESSFULLY: 'קישור לאיפוס סיסמא נשלח למייל',
    RESET_SUCCESFULLY: 'סיסמתך אופסה בהצלחה',
    INVALID_RE_PASSWORD: 'סיסמא אינה תואמת',
    FORGOT_PASSWORD: 'שכחת סיסמא?',
    INVALID_EMAIL: 'כתובת מייל אינה תקינה',
    SEND_LINK: 'שלח קישור',
    RESET_TITLE: 'אפס את הסיסמא לחשבונך',
    RESET_PASSWORD: 'איפוס סיסמא',
    EMAIL_EXPLAINATION: 'נשלח אליך מייל עם קישור לאיפוס הסיסמא. אנא בדוק גם בתקיית הספאם',
};
const registration = {
    REGISTER: 'הרשמה',
    USER_NAME: 'מספר טלפון',
    PASSWORD: 'סיסמא',
    FIRST_NAME: 'שם פרטי',
    LAST_NAME: 'שם משפחה',
    CITY: 'עיר',
    PICK: 'בחר',
    DATA_OF_BIRTH: 'תאריך לידה',
    RE_PASSWORD: 'חזור על הסיסמא',
    ACOUNT_EXIST_ERROR: 'חשבון זה קיים במערכת',
    SERVER_ERROR: 'שגיאה זמנית - אנא נסה שנית מאוחר יותר',
    EMAIL: 'מייל',
    REGISTER_SUCCESSFULLY: 'הרשמתך התקבלה בהצלחה והועברה לאישור',
};

const logout = {
    STAY_LOG_IN: 'רוצה להישאר',
    LOGOUT: 'התנתק',
};

const logo = {
    TITLE: 'תוצאות משחקי טניס',
    SUB_TITLE: 'המקום שלך לתעד תוצאות משחקים עם חברים',
};

const sessionStorage = {
    ACCESS_TOKEN: 'accessToken',
    PLAYER_PHONE_NUMBER: 'playerPhoneNumber',
    IS_LOGGED_IN: 'isLoggedIn',
};

const profile = {
    EDIT: 'ערוך פרטים',
};

const tabs = {
    LOGOUT_LABEL: 'יציאה',
    LOGOUT_VALUE: 'logout',
    ADD_MATCH_LABEL: 'הוספה',
    ADD_MATCH_VALUE: 'addMatch',
    RESULTS_LABEL: 'תוצאות',
    RESULTS_VALUE: 'results',
    TABLE_LABEL: 'טבלה',
    TABLE_VALUE: 'table',
    PROFILE_LABEL: 'פרופיל',
    PROFILE_VALUE: 'profile',
    MATCHES_TO_APPROVE: 'לאישורי',
    MATCHES_ARE_WAITING: 'ממתין ליריב',
    REJECTED_MATCHES: 'משחקים שסורבו',
    REJECTED_MATCHES_BY_ME: 'משחקים שסירבתי',
    NO_REJECTED_MATCHES: 'לא נמצאו תוצאות',
    NO_MATCHES_TO_APPROVE: 'אין משחקים הממתינים לאישורך',
    NO_MATCHES_WAITING_FOR_APPROVE: 'אין משחקים הממתינים לאישור שחקן יריב',
    MATCHES_TO_APPROVE_ID: 'matchesToApprove',
    MATCHES_ARE_WAITING_ID: 'matchesAreWaiting',
    MATCHES_REJECTED_MATCHES_ID: 'rejectedMatches',
    MATCHES_REJECTED_MATCHES_BY_ME_ID: 'rejectedMatchesByMe',
};

const addGame = {
    SUCCESS_MESSAGE: 'המשחק התווסף בהצלחה ונשלח לאישור יריב',
    MORE_DETAILS: 'ניתן לצפות בפרופיל',
    ADD_GAME_FAILED: 'שגיאה זמנית בהוספת משחק',
    TRY_AGAIN_LATER: 'נסה במועד מאוחר יותר',
    SELECT_PLAYERL: 'שחקן יריב',
    SELECT_COURT: 'מגרש',
    SELECT_DATE: 'תאריך',
    MATCH_SCORE: 'תוצאת משחק',
    SET_SCORE: 'תוצאות מערכות',
    PLAYER: 'בחר שחקן',
    FINISH: 'שלח',
    CONTINUE: 'המשך',
    BACK: 'חזור',
    SET: 'מערכה',
    COURT: 'מגרש',
    APPROVE_GAME: 'משחק שממתין לאישורך',
    WAITING_FOR_APPROVE_GAME: 'ממתין לאישור יריב',
    APPROVED: 'אישור המשחק',
    WAIT: 'אוודא שוב',
    REJECT: 'ביטול המשחק',
    ACTION_REJECT: 'reject',
    ACTION_APPROVE: 'approve',
};

module.exports = {
    LOGOUT_SCREEN: logout,
    LOGIN_SCREEN: login,
    ERROR_SCREEN: errorScreen,
    HTTP_FETCH: httpFetch,
    TABLE: table,
    RESULTS: results,
    ADD_GAME: addGame,
    SESSION_STORAGE: sessionStorage,
    TABS: tabs,
    MATCH_DETAILS: matchDetails,
    AUTH: auth,
    REGISTRATION: registration,
    LOGO: logo,
    PROFILE: profile,
};
