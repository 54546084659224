import React from 'react';
import './ButtomNavigationBar.css';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import constants from '../../constants/constants';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import ScoreboardTwoToneIcon from '@mui/icons-material/ScoreboardTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

function BottomNavigationBar(props) {
    const { tabValue, changeTabValue } = props;
    return (
        <div className='top-navigation-container'>
            <BottomNavigation className='bottom-navigation-custom' value={tabValue} onChange={changeTabValue}>
                <BottomNavigationAction
                    className='custom-button-tab'
                    label={constants.TABS.LOGOUT_LABEL}
                    value={constants.TABS.LOGOUT_VALUE}
                    icon={<LogoutTwoToneIcon />}
                />
                <BottomNavigationAction
                    className='custom-button-tab'
                    label={constants.TABS.ADD_MATCH_LABEL}
                    value={constants.TABS.ADD_MATCH_VALUE}
                    icon={<AddTwoToneIcon />}
                />
                <BottomNavigationAction
                    className='custom-button-tab'
                    label={constants.TABS.RESULTS_LABEL}
                    value={constants.TABS.RESULTS_VALUE}
                    icon={<ScoreboardTwoToneIcon />}
                />
                <BottomNavigationAction
                    className='custom-button-tab'
                    label={constants.TABS.TABLE_LABEL}
                    value={constants.TABS.TABLE_VALUE}
                    icon={<EmojiEventsTwoToneIcon />}
                />
                <BottomNavigationAction
                    className='custom-button-tab'
                    label={constants.TABS.PROFILE_LABEL}
                    value={constants.TABS.PROFILE_VALUE}
                    icon={<AccountCircleOutlinedIcon />}
                />
            </BottomNavigation>
        </div>
    );
}

export default BottomNavigationBar;
