import React from 'react';
import './MatchResultBox.css';
import { useDispatch } from 'react-redux';
import * as matchActions from '../../../redux/actions/matchActions';
import dateFormatter from '../../../generic actions/DateFormatter';

function MatchResultBox(props) {
    const { result } = props;

    const dispatch = useDispatch();

    const setSelectMatch = () => {
        dispatch(matchActions.setSelectedMatch(result));
    };

    return (
        <div onClick={setSelectMatch} className='result-box-container'>
            <div className='match-date-row row'>
                <span className='span-text-date'>
                    {dateFormatter(result.date)}, {result.courtName}
                </span>
            </div>
            <div className='match-details-row row'>
                <div className='player-column col-4'>
                    <span>{result.playerTwo.name}</span>
                </div>
                <div className='match-score-column col-4'>
                    <div className='row'>
                        <div className='col-4'>{result.playerTwo.score}</div>
                        <div className='col-4'>-</div>
                        <div className='col-4'>{result.playerOne.score}</div>
                    </div>
                </div>
                <div className='player-column col-4'>
                    <span>{result.playerOne.name}</span>
                </div>
            </div>
        </div>
    );
}

export default MatchResultBox;
