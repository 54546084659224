export const AUTH_SENDING_REQUEST = 'AUTH_SENDING_REQUEST';
export const AUTH_SENDING_REQUEST_FAILURE = 'AUTH_SENDING_REQUEST_FAILURE';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const LOGIN_SUCCESSFULLY = 'LOGIN_SUCCESSFULLY';
export const REGISTER_REQUEST_SENDING = 'REGISTER_REQUEST_SENDING';
export const SEND_EMAIL_PASSWORD_REQUEST_SENDING = 'SEND_EMAIL_PASSWORD_REQUEST_SENDING';
export const SEND_EMAIL_PASSWORD_REQUEST_SUCCESS = 'SEND_EMAIL_PASSWORD_REQUEST_SUCCESS';
export const SEND_EMAIL_PASSWORD_REQUEST_FAILURE = 'SEND_EMAIL_PASSWORD_REQUEST_FAILURE';
export const REGISTER_REQUEST_SUCCESSFULLY = 'REGISTER_REQUEST_SUCCESSFULLY';
export const SEND_RESET_PASSWORD_REQUEST_SENDING = 'SEND_RESET_PASSWORD_REQUEST_SENDING';
export const SEND_RESET_PASSWORD_REQUEST_SUCCESS = 'SEND_RESET_PASSWORD_REQUEST_SUCCESS';
export const SEND_RESET_PASSWORD_REQUEST_FAILURE = 'SEND_RESET_PASSWORD_REQUEST_FAILURE';
export const REGISTER_REQUEST_FAILURE = 'REGISTER_REQUEST_FAILURE';
export const AUTH_RESET_ERRORS = 'RESET_ERRORS';
export const LOGOUT = 'LOGOUT';
export const LOGIN_AS_GUEST = 'LOGIN_AS_GUEST';
