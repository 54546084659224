import React from 'react';
import './ServerError.css';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import constants from '../../../constants/constants';

function ServerError(props) {
    const handleReload = () => {
        props.reload();
    };

    return (
        <div className='server-error-container row'>
            <div className='text-row col-12 row'>
                <ErrorOutlineIcon className='error-icon' sx={{ fontSize: 18 }} />
                <span>{constants.ERROR_SCREEN.OOPS}</span>
            </div>
            <div className='button-row col-12 row'>
                <Button onClick={handleReload} className='custom-css-button' size='small' variant='outlined'>
                    {constants.ERROR_SCREEN.RELOAD}
                </Button>
            </div>
        </div>
    );
}
export default ServerError;
