import './LogoutModal.css';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import constants from '../../../constants/constants';

function LogoutModal(props) {
    const { handleCloseLogoutModal, handleLogout, prevTabValue } = props;
    const [isOpen, setIsOpen] = useState(true);

    const handleCloseModal = () => {
        setIsOpen(false);
        handleCloseLogoutModal(prevTabValue);
    };

    const handleLogoutClick = () => {
        setIsOpen(false);
        handleLogout();
    };

    return (
        <Modal open={isOpen}>
            <div className='logout-modal-container'>
                <div className='logout-modal-header'>
                    <span>יציאה מהמערכת</span>
                    <CloseIcon className='logout-modal-close-icon' onClick={handleCloseModal} />
                </div>
                <div className='logout-modal-context row'>
                    <div className='logout-container col-6'>
                        <Button onClick={handleLogoutClick} size='small' variant='outlined'>
                            {constants.LOGOUT_SCREEN.LOGOUT}
                        </Button>
                    </div>
                    <div className='stay-login-container col-6'>
                        <Link component='button' variant='body2' onClick={handleCloseModal}>
                            {constants.LOGOUT_SCREEN.STAY_LOG_IN}
                        </Link>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default LogoutModal;
