import * as React from "react";
import "./SelectDateSection.css";
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ButtonSteps from "../ButtonSteps/ButtonSteps";
import "react-datepicker/dist/react-datepicker.css";

function SelectDateSection(props) {
  const [date, setDate] = useState(new Date());

  const handleChange = (dateInput) => {
    setDate(dateInput);
    props.dateSelectedChanged(dateInput);
  };

  useEffect(() => {
    setDate(props.date);
  });

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          format="dd/MM/yyyy"
          defaultValue={date}
          onChange={(date) => handleChange(date)}
        />
      </LocalizationProvider>
      <ButtonSteps
        isFinalStep={false}
        isBackDisabled={false}
        isContinueDisabled={false}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
      />
    </div>
  );
}

export default SelectDateSection;
