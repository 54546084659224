import React from 'react';
import './Tab.css';

function Tab(props) {
    const { activeTab, changeTabHandler, tabLabel, tabId } = props;

    return (
        <div>
            <span
                onClick={(e) => {
                    changeTabHandler(e.target.id);
                }}
                className={`profile-tab-span ${activeTab === tabId ? 'profile-tab-span-active' : ''}`}
                id={tabId}
            >
                {tabLabel}
            </span>
        </div>
    );
}

export default Tab;
