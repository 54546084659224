import {
    SET_ACCESS_TOKEN,
    LOGIN_SUCCESSFULLY,
    AUTH_SENDING_REQUEST,
    AUTH_SENDING_REQUEST_FAILURE,
    LOGOUT,
    REGISTER_REQUEST_SENDING,
    REGISTER_REQUEST_SUCCESSFULLY,
    REGISTER_REQUEST_FAILURE,
    AUTH_RESET_ERRORS,
    LOGIN_AS_GUEST,
    SEND_EMAIL_PASSWORD_REQUEST_FAILURE,
    SEND_EMAIL_PASSWORD_REQUEST_SENDING,
    SEND_EMAIL_PASSWORD_REQUEST_SUCCESS,
    SEND_RESET_PASSWORD_REQUEST_SENDING,
    SEND_RESET_PASSWORD_REQUEST_SUCCESS,
    SEND_RESET_PASSWORD_REQUEST_FAILURE,
} from '../constants/authConstants';
import constants from '../../constants/constants';

const initialState = {
    isLoggedIn: false,
    isGuest: false,
    isLoading: false,
    error: null,
    registration: {
        isRegister: false,
        isLoading: false,
        error: null,
    },
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESSFULLY:
            sessionStorage.setItem(constants.SESSION_STORAGE.PLAYER_PHONE_NUMBER, action.phoneNumber);
            return {
                ...state,
                registration: {
                    ...state.registration,
                    isRegister: false,
                },
                isLoggedIn: true,
                isLoading: false,
                isGuest: false,
                error: null,
            };
        case LOGIN_AS_GUEST:
            return {
                ...state,
                registration: {
                    ...state.registration,
                    isRegister: false,
                },
                isLoggedIn: true,
                isLoading: false,
                isGuest: true,
                error: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                isLoading: false,
                isGuest: false,
                error: null,
            };
        case SET_ACCESS_TOKEN:
            sessionStorage.setItem(constants.SESSION_STORAGE.ACCESS_TOKEN, action.token);
            return { ...state, isLoading: false, error: null };
        case AUTH_SENDING_REQUEST:
            return { ...state, isLoading: true, error: null };
        case REGISTER_REQUEST_SENDING:
            return {
                ...state,
                registration: {
                    ...state.registration,
                    isLoading: true,
                    error: null,
                },
            };
        case SEND_RESET_PASSWORD_REQUEST_SENDING:
        case SEND_EMAIL_PASSWORD_REQUEST_SENDING:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case SEND_RESET_PASSWORD_REQUEST_SUCCESS:
        case SEND_RESET_PASSWORD_REQUEST_FAILURE:
        case SEND_EMAIL_PASSWORD_REQUEST_SUCCESS:
        case SEND_EMAIL_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: null,
            };
        case REGISTER_REQUEST_SUCCESSFULLY:
            return {
                ...state,
                registration: {
                    ...state.registration,
                    isRegister: true,
                    isLoading: false,
                    error: null,
                },
            };
        case REGISTER_REQUEST_FAILURE:
            return {
                ...state,
                registration: {
                    ...state.registration,
                    isLoading: false,
                    error: action.error,
                },
            };
        case AUTH_SENDING_REQUEST_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        case AUTH_RESET_ERRORS:
            return {
                ...state,
                error: null,
                registration: {
                    ...state.registration,
                    error: null,
                },
            };
        default:
            return state;
    }
};

export default authReducer;
