import { SET_COURTS, COURT_SENDING_REQUEST_FAILURE, COURT_SENDING_REQUEST } from '../constants/courtConstants';
import constants from '../../constants/constants';
import AuthServiceImpl from '../../service/AuthServiceImpl';
import ToastUtil from '../../utils/ToastUtils';

export const getAllCourts = () => {
    return async (dispatch) => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        try {
            dispatch({
                type: COURT_SENDING_REQUEST,
            });
            const courts = await AuthServiceImpl.getAllCourts(token);
            if (courts?.data?.code) {
                dispatch({
                    type: COURT_SENDING_REQUEST_FAILURE,
                    error: courts?.data,
                });
                ToastUtil.error('שגיאה זמנית');
            } else {
                dispatch({
                    type: SET_COURTS,
                    courts: courts?.data,
                });
            }
        } catch (error) {
            dispatch({
                type: COURT_SENDING_REQUEST_FAILURE,
                error: error?.response ? error?.response?.data : error,
            });
            ToastUtil.error('שגיאה זמנית');
        }
    };
};
