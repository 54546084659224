import React from "react";
import "./MatchHeader.css";
import dateFormatter from "../../../generic actions/DateFormatter";

function MatchHeader(props) {
  const matchDetails = props.matchDetails;
  return (
    <div className="match-header-container">
      <div className="match-date-details">
        <span className="span-date">{dateFormatter(matchDetails.date)}, </span>
        <span className="span-date">{matchDetails.courtName}</span>
      </div>
      <div className="match-players-details">
        <div className="player-container">
          <img
            className="img-player-round"
            src={matchDetails.playerOne.img}
            alt="player"
          />
          <div>
            <span className="span-player-name-match-header">
              {matchDetails.playerOne.name}
            </span>
          </div>
        </div>
        <div className="match-score-container">
          <span className="span-match-score">
            {matchDetails.playerOne.score}
          </span>
          <span className="span-match-score">-</span>
          <span className="span-match-score">
            {matchDetails.playerTwo.score}
          </span>
        </div>
        <div className="player-container">
          <img
            className="img-player-round"
            src={matchDetails.playerTwo.img}
            alt="player"
          />
          <div>
            <span className="span-player-name-match-header">
              {matchDetails.playerTwo.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchHeader;
