import * as React from 'react';
import './SelectCourtSection.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import constants from '../../../../constants/constants';
import { useEffect } from 'react';
import ButtonSteps from '../ButtonSteps/ButtonSteps';

function SelectCourtSection(props) {
    const [courtSelectedId, setcourtSelected] = useState('');
    const handleChange = (value) => {
        const courtId = value.target.value;
        setcourtSelected(courtId);
        props.courtSelectedIdChanged(courtId);
    };

    useEffect(() => {
        setcourtSelected(props.courtId);
    });

    return (
        <div>
            <Select value={courtSelectedId} size='small' onChange={(value) => handleChange(value)} displayEmpty>
                <MenuItem value=''>
                    <em>{constants.ADD_GAME.COURT}</em>
                </MenuItem>
                {props.courts.map((court, index) => {
                    return (
                        <MenuItem key={index} value={(index + 1).toString()}>
                            {court.name}
                        </MenuItem>
                    );
                })}
            </Select>
            <ButtonSteps
                isBackDisabled={false}
                isFinalStep={false}
                isContinueDisabled={courtSelectedId === '' ? true : false}
                handleNext={props.handleNext}
                handleBack={props.handleBack}
            />
        </div>
    );
}

export default SelectCourtSection;
