import ServiceClass from './ServiceClass';
import ClientConfig from '../config';

class PlayerServiceImpl extends ServiceClass {
    async getPlayerByPhoneNumber(token, phoneNumber) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.get(`/api/playerManagement/player/${phoneNumber}`, headers);
    }
    async getPlayerById(token, playerId) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.get(`/api/playerManagement/player/profile/${playerId}`, headers);
    }
    async getPlayerGames(token) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.get(`/api/playerManagement/players/games`, headers);
    }
    async getAllPlayersList(token) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.get(`/api/playerManagement/players/players`, headers);
    }
}

export default new PlayerServiceImpl(ClientConfig.apiBaseHost, '');
