import ServiceClass from './ServiceClass';
import ClientConfig from '../config';

class AuthServiceImpl extends ServiceClass {
    async getAllCities() {
        return await this.get('/api/city/cities');
    }
    async getAllCourts(token) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.get('/api/court/courts', headers);
    }
    async getAccessToken(userName) {
        return await this.post('/api/entry/token/getToken', {}, { phoneNumber: userName });
    }
    async login(token, phoneNumber, password) {
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        return await this.post('/api/entry/getPlayer', { phoneNumber, password }, headers);
    }
    async register(player) {
        return await this.post('/api/registration/register', player);
    }

    async sendEmailforgotPassword(payload) {
        return await this.post(`/api/entry/forgot-password`, payload);
    }

    async resetPassword(payload) {
        return await this.post(`/api/entry/reset-password`, { password: payload.password }, {}, { token: payload.token });
    }
}

export default new AuthServiceImpl(ClientConfig.apiBaseHost, '');
