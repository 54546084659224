import React, { useEffect } from 'react';
import './Results.css';
import { useSelector, useDispatch } from 'react-redux';
import MatchResultBox from '../../../components/mobile/MatchResultBox/MatchResultBox';
import * as matchActions from '../../../redux/actions/matchActions';
import * as playerActions from '../../../redux/actions/playerAction';
import Match from '../Match/Match';
import ServerError from '../../../components/mobile/ServerError/ServerError';
import SearchMatchesByPlayer from '../../../components/mobile/SearchMatchesByPlayer/SearchMatchesByPlayer';
import constants from '../../../constants/constants';

function Results() {
    const {
        matchsResults,
        isLoading: matchIsLoading,
        error: matchError,
        selectedMatch,
        selectedPlayerForResults,
    } = useSelector((state) => state.match);
    const { isLoading: playerIsLoading, error: playerError } = useSelector((state) => state.player);

    const isLoading = matchIsLoading || playerIsLoading;
    const error = playerError || matchError;

    const dispatch = useDispatch();

    const onSelectPlayerHandler = (e, player) => {
        const playerId = player?.playerId;
        if (playerId) {
            dispatch(matchActions.getAllMatchsResultsByPlayerId(playerId));
            dispatch(matchActions.setSelectedPlayerForResults(player));
        } else {
            dispatch(matchActions.getAllMatchsResults());
            dispatch(matchActions.releaseSelectedPlayerForResults());
        }
        dispatch(matchActions.releaseSelectedOpponentForResults());
    };

    const onSelectOpponentHandler = (e, player) => {
        const opponentId = player?.playerId;
        if (opponentId) {
            dispatch(matchActions.getAllMatchsResultsByPlayerIdAndOpponentId(selectedPlayerForResults.playerId, opponentId));
            dispatch(matchActions.setSelectedOpponentForResults(player));
        } else {
            dispatch(matchActions.getAllMatchsResultsByPlayerId(selectedPlayerForResults.playerId));
            dispatch(matchActions.releaseSelectedOpponentForResults());
        }
    };

    const fetchData = () => {
        if (selectedPlayerForResults) {
            dispatch(matchActions.getAllMatchsResultsByPlayerId(selectedPlayerForResults.playerId));
        } else {
            dispatch(matchActions.getAllMatchsResults());
        }
        dispatch(playerActions.getAllPlayersList());
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='results-container'>
            {error && <ServerError reload={fetchData} />}

            {!error && !selectedMatch && !isLoading && (
                <SearchMatchesByPlayer onSelectPlayerHandler={onSelectPlayerHandler} onSelectOpponentHandler={onSelectOpponentHandler} />
            )}
            {!error && !selectedMatch && !isLoading && (
                <div className='matches-results-container'>
                    {matchsResults?.length > 0 ? (
                        matchsResults?.map((result, i) => {
                            return <MatchResultBox key={i + 1} result={result} />;
                        })
                    ) : (
                        <div className='no-result-container'>
                            <span className='no-result-span-text'>{constants.RESULTS.NO_RESULTS}</span>
                        </div>
                    )}
                </div>
            )}
            {!error && selectedMatch && !isLoading && <Match />}
        </div>
    );
}

export default Results;
