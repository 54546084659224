import * as React from 'react';
import './PlayersTable.css';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import constants from '../../../constants/constants';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    }, // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function PlayersTable(props) {
    const { playerGames } = props;
    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });

    const handleRowClick = (player) => {
        props.handleRowClick(player);
    };

    return (
        <div className='table-container'>
            <TableContainer component={Paper}>
                <Table aria-label='customized table'>
                    <TableHead style={{ overflowX: 'initial' }}>
                        <TableRow>
                            <StyledTableCell align='center'>{constants.TABLE.PLACE}</StyledTableCell>
                            <StyledTableCell align='center'>{constants.TABLE.PLAYER_NAME}</StyledTableCell>
                            <StyledTableCell align='center'>
                                {isMobileScreen ? constants.TABLE.MOBILE_MATCHES : constants.TABLE.MATCHES}
                            </StyledTableCell>
                            <StyledTableCell align='center'>{isMobileScreen ? constants.TABLE.MOBILE_WINS : constants.TABLE.WINS}</StyledTableCell>
                            <StyledTableCell align='center'>{isMobileScreen ? constants.TABLE.MOBILE_LOSES : constants.TABLE.LOSES}</StyledTableCell>
                            <StyledTableCell align='center'>
                                {isMobileScreen ? constants.TABLE.MOBILE_WIN_STATS : constants.TABLE.WIN_STATS}
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {playerGames.map((player, i) => (
                            <StyledTableRow
                                className='table-player-row'
                                key={i}
                                onClick={() => {
                                    handleRowClick(player);
                                }}
                            >
                                <StyledTableCell align='center'>{i + 1}</StyledTableCell>
                                <StyledTableCell className='player-cell-custom' align='center'>
                                    {player.firstName} {player.lastName}
                                </StyledTableCell>
                                <StyledTableCell align='center'>{player.matches}</StyledTableCell>
                                <StyledTableCell align='center'>{player.wins}</StyledTableCell>
                                <StyledTableCell align='center'>{player.losses}</StyledTableCell>
                                <StyledTableCell align='center'>{player.winPercentage}%</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default PlayersTable;
