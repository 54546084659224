import React, { useEffect } from 'react';
import './AddMatch.css';
import { useSelector, useDispatch } from 'react-redux';
import * as courtActions from '../../../redux/actions/courtActions';
import * as playerActions from '../../../redux/actions/playerAction';
import ServerError from '../../../components/mobile/ServerError/ServerError';
import WizardSteps from '../../../components/mobile/AddMatchForm/WizardSteps';

function AddMatch() {
    const { isLoading: playerIsLoading, error: playerError } = useSelector((state) => state.player);
    const { isLoading: courtIsLoading, error: courtError } = useSelector((state) => state.court);
    const error = playerError || courtError;
    const isLoading = playerIsLoading || courtIsLoading;
    const dispatch = useDispatch();

    const fetchData = async () => {
        await dispatch(playerActions.getAllPlayersList());
        await dispatch(courtActions.getAllCourts());
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='add-match-container'>
            {error && !isLoading && <ServerError reload={fetchData} />}
            {!error && !isLoading && <WizardSteps />}
        </div>
    );
}

export default AddMatch;
