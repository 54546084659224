import './Footer.css';

const Footer = () => {
    return (
        <div className='footer-container'>
            <span>
                <a className='footer-link' href='https://www.mtdigital.co.il/'>
                    @ Powered by MTDigital
                </a>
            </span>
        </div>
    );
};

export default Footer;
