import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Recaptcha = (props) => {
    const { captchaRef } = props;

    return <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY} />;
};

export default Recaptcha;
