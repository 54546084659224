import { Route, Routes } from 'react-router-dom';
import ResetPasswordForm from '../components/mobile/ResetPasswordForm/ResetPasswordForm';
import { RoutesUrl } from './RoutesUrls';
import Home from '../pages/mobile/home/Home';

const Router = () => {
    return (
        <Routes>
            <Route path={RoutesUrl.welcome} element={<Home />}></Route>
            <Route path={RoutesUrl.restPassword} element={<ResetPasswordForm />}></Route>
        </Routes>
    );
};

export default Router;
