import './App.css';
import './styles/common.css';
import Footer from './components/mobile/footer/Footer';
import ToastComponent from './components/mobile/toastComponent/ToastComponent';
import { BrowserRouter } from 'react-router-dom';
import Router from './router/Router';

function App() {
    return (
        <>
            <BrowserRouter>
                <Router />
                <Footer />
                <ToastComponent />
            </BrowserRouter>
        </>
    );
}

export default App;
