import './LoginForm.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import constants from '../../../constants/constants';

function LoginForm(props) {
    const { submitLoginForm, forgotPasswordHandler } = props;

    const validationSchema = yup.object().shape({
        userName: yup.string().required(constants.LOGIN_SCREEN.REQUIRE_FILED),
        password: yup.string().required(constants.LOGIN_SCREEN.REQUIRE_FILED),
    });

    const formik = useFormik({
        initialValues: {
            userName: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            submitLoginForm(values);
        },
    });

    return (
        <div className='login-form-container'>
            <form onSubmit={formik.handleSubmit}>
                <div className='login-form-inputs-container'>
                    <div className='username-input-row row'>
                        <span className='input-label-span'>{constants.LOGIN_SCREEN.USER_NAME}</span>
                    </div>
                    <div className='username-input-row username-input-field row'>
                        <TextField
                            id='usernameInput'
                            autoComplete='current-password'
                            variant='outlined'
                            inputProps={{ maxLength: 10 }}
                            onChange={formik.handleChange('userName')}
                            error={formik.touched.userName && Boolean(formik.errors.userName)}
                            helperText={formik.touched.userName && formik.errors.userName}
                            size='small'
                        />
                    </div>
                    <div className='password-input-row row'>
                        <span className='input-label-span'>{constants.LOGIN_SCREEN.PASSWORD}</span>
                        <span onClick={forgotPasswordHandler} className='input-label-span forgot-password-span'>
                            {constants.LOGIN_SCREEN.FORGOT_PASSWORD}
                        </span>
                    </div>
                    <div className='username-input-row username-input-field row'>
                        <TextField
                            id='passwordInput'
                            type='password'
                            autoComplete='current-password'
                            inputProps={{ maxLength: 10 }}
                            onChange={formik.handleChange('password')}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            size='small'
                            variant='outlined'
                        />
                    </div>
                    <div className='login-button-row'>
                        <Button type='submit' size='small' variant='outlined'>
                            {constants.LOGIN_SCREEN.LOGIN}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default LoginForm;
