import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as authActions from '../../../redux/actions/authActions';
import PlayerProfile from '../../../pages/mobile/PlayerProfile/PlayerProfile';
import BottomNavigationBar from '../../../navigation/bottomNavigation/BottomNavigationBar';
import Table from '../../../pages/mobile/Table/Table';
import Results from '../../../pages/mobile/Results/Results';
import AddMatch from '../../../pages/mobile/AddMatch/AddMatch';
import LogoutModal from '../../../components/mobile/LogoutModal/LogoutModal';
import Auth from '../../../pages/mobile/Auth/Auth';
import constants from '../../../constants/constants';
import Loader from '../../../components/mobile/loader/Loader';

const Home = () => {
    const { isLoading: playerIsLoading, player } = useSelector((state) => state.player);
    const { isLoading: matchIsLoading } = useSelector((state) => state.match);
    const { isLoading: authIsLoading, isLoggedIn, isGuest } = useSelector((state) => state.auth);

    const [tabValue, setTabValue] = useState(constants.TABS.PROFILE_VALUE);
    const [prevTabValue, setPrevTabValue] = useState(constants.TABS.PROFILE_VALUE);

    const isLoading = playerIsLoading || matchIsLoading || authIsLoading;

    const dispatch = useDispatch();

    const changeTabValue = (e, newValue) => {
        setPrevTabValue(tabValue);
        if (newValue !== tabValue) {
            setTabValue(newValue);
        }
    };
    const handleCloseLogoutModal = (prevTabValue) => {
        setTabValue(prevTabValue);
    };
    const handleLogout = () => {
        dispatch(authActions.logout());
        setTabValue(constants.TABS.PROFILE_VALUE);
    };

    useEffect(() => {
        const token = sessionStorage.getItem(constants.SESSION_STORAGE.ACCESS_TOKEN);
        if (!token) {
            handleLogout();
        }
    }, []);

    return (
        <div className='root-container'>
            {((isLoggedIn && Object.keys(player).length !== 0) || (isLoggedIn && isGuest)) && (
                <div className='app-container'>
                    <BottomNavigationBar tabValue={tabValue} changeTabValue={changeTabValue} />
                    <div className='context-container'>
                        {isLoading && (
                            <div className='loader-container'>
                                <Loader />
                            </div>
                        )}
                        {tabValue === constants.TABS.PROFILE_VALUE && <PlayerProfile />}
                        {tabValue === constants.TABS.TABLE_VALUE && <Table />}
                        {tabValue === constants.TABS.RESULTS_VALUE && <Results />}
                        {tabValue === constants.TABS.ADD_MATCH_VALUE && <AddMatch />}
                        {tabValue === constants.TABS.LOGOUT_VALUE && (
                            <LogoutModal handleCloseLogoutModal={handleCloseLogoutModal} handleLogout={handleLogout} prevTabValue={prevTabValue} />
                        )}
                    </div>
                </div>
            )}
            {!isLoggedIn && <Auth />}
        </div>
    );
};

export default Home;
