import './Auth.css';
import LoginForm from '../../../components/mobile/LoginForm/LoginForm';
import RegistrationForm from '../../../components/mobile/RegistrationForm/RegistrationForm';
import * as authActions from '../../../redux/actions/authActions';
import * as playerActions from '../../../redux/actions/playerAction';
import { useSelector, useDispatch } from 'react-redux';
import Logo from '../../../components/mobile/Logo/Logo';
import { useState } from 'react';
import constants from '../../../constants/constants';
import ForgotPasswordForm from '../../../components/mobile/ForgotPasswordForm/ForgotPasswordForm';
import Loader from '../../../components/mobile/loader/Loader';

function Auth() {
    const { isLoading } = useSelector((state) => state.auth);
    const [currentView, setCurrentView] = useState(constants.AUTH.LOGIN_VIEW);

    const dispatch = useDispatch();

    const submitLoginForm = async (payload) => {
        await dispatch(authActions.getAccessToken(payload.userName));
        const player = await dispatch(authActions.login(payload));
        if (player) {
            await dispatch(playerActions.setPlayer());
        }
    };

    const loginAsGuest = async () => {
        await dispatch(authActions.getAccessToken('000'));
        dispatch(authActions.loginAsGuest());
    };

    const submitRegistrationForm = async (payload) => {
        dispatch(authActions.register(payload));
    };

    const submitForgotPasswordForm = async (payload) => {
        dispatch(authActions.sendEmailforgotPassword(payload));
    };

    const changeViewHandler = (view) => {
        console.log('dsdsa');
        setCurrentView(view);
        dispatch(authActions.resetErrorMessages());
    };

    return (
        <div className='auth-container'>
            <Logo />
            {!isLoading && (
                <>
                    {currentView === constants.AUTH.LOGIN_VIEW && (
                        <LoginForm
                            submitLoginForm={submitLoginForm}
                            forgotPasswordHandler={() => {
                                changeViewHandler(constants.AUTH.FORGOT_PASSWORD_VIEW);
                            }}
                        />
                    )}
                    {currentView === constants.AUTH.REGISTER_VIEW && <RegistrationForm submitRegistrationForm={submitRegistrationForm} />}
                    {currentView === constants.AUTH.FORGOT_PASSWORD_VIEW && (
                        <ForgotPasswordForm submitForgotPasswordForm={submitForgotPasswordForm} />
                    )}

                    <div className='create-new-account-text-container'>
                        <div className='create-account-login row'>
                            <span
                                onClick={() =>
                                    changeViewHandler(
                                        currentView === constants.AUTH.LOGIN_VIEW ? constants.AUTH.REGISTER_VIEW : constants.AUTH.LOGIN_VIEW
                                    )
                                }
                                className='create-account-text-button'
                            >
                                {currentView === constants.AUTH.LOGIN_VIEW ? constants.AUTH.CREATE_ACCOUNT : constants.AUTH.LOGIN}
                            </span>
                        </div>
                    </div>
                    {currentView === constants.AUTH.LOGIN_VIEW && (
                        <div className='join-as-guest-container row'>
                            <span onClick={loginAsGuest} className='create-account-text-button'>
                                {constants.AUTH.GUEST}
                            </span>
                        </div>
                    )}
                </>
            )}

            {isLoading && (
                <div className='loader-container'>
                    <Loader />
                </div>
            )}
        </div>
    );
}

export default Auth;
